import { Injectable, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { NotificationComponent } from '@components/shared/notification/notification.component';
import { SentryService } from '@modules/sentry/sentry.service';
import { filter, map, takeUntil } from 'rxjs/operators';
import { UnSubscriptionDirective } from '../directives/unsubscribe.directive';
import { EnvironmentService } from './environment.service';

@Injectable({
    providedIn: 'root',
})
export class AppUpdateService extends UnSubscriptionDirective {
    private applicationUpdate = inject(SwUpdate);
    private sentryService = inject(SentryService);
    private environmentService = inject(EnvironmentService);
    private dialog = inject(MatDialog);

    handleUpdates(): void {
        if (this.applicationUpdate.isEnabled) {
            this.applicationUpdate.versionUpdates
                .pipe(
                    filter((event): event is VersionReadyEvent => event.type === 'VERSION_READY'),
                    map((event) => ({
                        type: 'UPDATE_AVAILABLE',
                        current: event.currentVersion,
                        available: event.latestVersion,
                    })),
                    takeUntil(this.unsubscribe$),
                )
                .subscribe((event) => {
                    console.log('App Update available:', event);
                    this.applicationUpdate.activateUpdate().then(() => {
                        const dialog = this.dialog.open(NotificationComponent);
                        dialog
                            .afterClosed()
                            .pipe(takeUntil(this.unsubscribe$))
                            .subscribe((isConfirmed: boolean) => {
                                if (isConfirmed) {
                                    document.location.reload();
                                }
                            });
                    });
                });

            // In the preceding scenario, the service worker is not able to serve an asset that would normally be cached.
            // That particular application version is broken and there is no way to fix the state of the client without reloading the page
            this.applicationUpdate.unrecoverable.pipe(takeUntil(this.unsubscribe$)).subscribe((event) => {
                if (this.environmentService.getEnvinronment().isProduction) {
                    this.sentryService.captureMessage('service worker recovering error: ' + event.reason, 'error');

                    console.log(
                        'An error occurred that we cannot recover from:\n' +
                            event.reason +
                            '\n\nPlease reload the page.',
                    );
                }
            });
        }
    }
}
