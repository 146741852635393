import { ISmsSendingResult } from '@models/backend/responses';
import { TranslateService } from '@ngx-translate/core';

export class SendSmsErrorMessageHelper {
    static generateSmsSendingErrorMessage(
        smsSendingResults: ISmsSendingResult[],
        translateService: TranslateService,
    ): string {
        const invalidPhoneResults = smsSendingResults.filter((r) => r.type === 'invalid-phone-number');
        const failedResults = smsSendingResults.filter((r) => r.type !== 'ok');

        if (invalidPhoneResults.length && invalidPhoneResults.length === failedResults.length) {
            return SendSmsErrorMessageHelper.getErrorMessage(
                translateService,
                invalidPhoneResults,
                'SHARED_COMPONENT.VIEWING_CONFIRMATION_INVALID_PHONE_ERROR',
            );
        }

        return SendSmsErrorMessageHelper.getErrorMessage(
            translateService,
            failedResults,
            'SHARED_COMPONENT.VIEWING_CONFIRMATION_GENERAL_ERROR',
        );
    }

    private static getErrorMessage(
        translateService: TranslateService,
        results: ISmsSendingResult[],
        messageKey: string,
    ) {
        const prospects = results.map((r) => `${r.data.name} ${r.data.phone}`).join(', ');
        return translateService.instant(messageKey, { prospects });
    }
}
