<div
    class="publish flex-footer"
    [ngClass]="{
        minHeight: currentView === 'Loading',
        'let-mat-dialog min-dialog-height': currentView === 'SelectPortals',
        safariMobileHeight: isMobileSafariBrowser,
    }"
    [ngSwitch]="currentView"
>
    <section class="head">
        <article class="subtitle">{{ title | translate }}</article>
        <button mat-icon-button [disabled]="isLoading" clickAndKeydown (actionTriggered)="cancel()">
            <mat-icon>close</mat-icon>
        </button>
    </section>

    <aside class="loader">
        <loading-indicator [isEmbedded]="true"></loading-indicator>
    </aside>

    <section class="wait" *ngSwitchCase="'Loading'">{{ loadingText | translate }}</section>

    @if (currentView === 'SelectPortals' && !canPublishToAkeliusWebsite()) {
        <ak-message-box class="warning" [type]="'warning'">
            {{ 'VALIDATION_MESSAGES.INVALID_PICTURES_FOR_AKELIUS_WEBSITE' | translate }}
        </ak-message-box>
    }

    <section class="select-portals" *ngSwitchCase="'SelectPortals'">
        <article class="heading">{{ 'SHARED_COMPONENT.SELECT_PORTALS' | translate }}</article>

        <aside class="portals">
            <mat-checkbox
                *ngFor="let portal of publicationPortals; trackBy: byIndex"
                [(ngModel)]="portal.isSelected"
                [disabled]="shouldDisablePortalSelector(portal.context)"
            >
                {{ 'PORTALS.' + portal.context | translate }}</mat-checkbox
            >
        </aside>

        <ak-message-box class="warning prospect-limit" [type]="'info'">
            {{ 'PUBLICATION.PROSPECT_LIMIT_INFO' | translate }}
        </ak-message-box>

        <mat-form-field class="prospect-limit">
            <mat-label>{{ 'PUBLICATION.PROSPECT_LIMIT_LABEL' | translate }}</mat-label>
            <input [formControl]="prospectLimitControl" type="number" matInput />
        </mat-form-field>

        <aside class="actions">
            <button mat-flat-button clickAndKeydown (actionTriggered)="cancel()">
                {{ 'SHARED_COMPONENT.CANCEL' | translate }}
            </button>
            <button
                mat-flat-button
                color="primary"
                clickAndKeydown
                (actionTriggered)="portalSelectionConfirmed()"
                [disabled]="!canPublish"
            >
                {{ 'SHARED_COMPONENT.PUBLISH' | translate }}
            </button>
        </aside>
    </section>

    <section class="success" *ngSwitchCase="'Success'">
        <article>{{ successText | translate }}</article>
        <aside class="list" *ngIf="workflowResults.length > 0">
            <div class="page" *ngFor="let result of workflowResults; trackBy: byIndex">
                <span *ngIf="!result.url">- {{ 'PORTALS.' + result.portalName | translate }}</span>
                <span *ngIf="result.url"
                    >-
                    <a class="link" href="{{ result.url }}" target="_blank" rel="noopener">{{
                        'PORTALS.' + result.portalName | translate
                    }}</a></span
                >
                <i class="material-icons" [ngClass]="result.isSuccess ? 'success' : 'error'">{{
                    result.isSuccess ? 'check_circle' : 'error'
                }}</i>
            </div>
        </aside>

        <aside class="actions">
            <button mat-flat-button clickAndKeydown (actionTriggered)="navigateToAdvert()">
                {{ 'SHARED_COMPONENT.ADVERT' | translate }}
            </button>
            <button mat-flat-button clickAndKeydown (actionTriggered)="navigateToDashboard()">
                {{ 'SHARED_COMPONENT.DASHBOARD' | translate }}
            </button>
            <a style="display: none" #downloadLink></a>
        </aside>
    </section>

    <section class="error" *ngSwitchCase="'Error'">
        <ak-message-box [type]="'error'">
            {{ errorText }}
        </ak-message-box>

        <aside class="actions">
            <button mat-flat-button clickAndKeydown (actionTriggered)="navigateToAdvert()">
                {{ 'SHARED_COMPONENT.ADVERT' | translate }}
            </button>
            <button mat-flat-button clickAndKeydown (actionTriggered)="navigateToDashboard()">
                {{ 'SHARED_COMPONENT.DASHBOARD' | translate }}
            </button>
        </aside>
    </section>

    <section class="unpublish-reason" *ngSwitchCase="'SelectUnpublishReason'">
        <mat-form-field>
            <mat-label>{{ 'SHARED_COMPONENT.MODAL_UNPUBLISH_REASON' | translate }}</mat-label>
            <mat-select [(ngModel)]="selectedReason">
                <mat-option *ngFor="let item of unpublishReasonItems; trackBy: byIndex" [value]="item.context">
                    {{ item.name | translate }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <aside class="actions">
            <button mat-flat-button clickAndKeydown (actionTriggered)="cancel()">
                {{ 'SHARED_COMPONENT.CANCEL' | translate }}
            </button>
            <button mat-flat-button color="primary" clickAndKeydown (actionTriggered)="unpublishReasonConfirmed()">
                {{ 'SHARED_COMPONENT.SUBMIT' | translate }}
            </button>
        </aside>
    </section>
</div>
