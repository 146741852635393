import { ChangeDetectorRef, Component, OnInit, ViewChild, inject } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LoadingIndicatorComponent } from '@components/shared';
import { DownloadIcsComponent } from '@components/shared/download-ics/download-ics.component';
import { SendSmsErrorMessageHelper } from '@helpers/send-sms-error-message.helper';
import { TimeHelper } from '@helpers/time.helper';
import { AdvertViewModel } from '@models/advert/advert';
import { getSectionKeyfacts, getValueOrUndefined } from '@models/advert/advert-keyfact-helper';
import { extractContacts } from '@models/advert/extractors/contacts-extractor';
import { IHandleEventCreateInviteAndConfirmResponseBody, IViewingsResponseBody } from '@models/backend/responses';
import { Selectable } from '@models/common/selectable';
import { GoogleAnalyticsEvents } from '@models/google-analytics/google-analytics-events';
import { ProspectViewModel } from '@models/prospect';
import {
    IInviteeViewModel,
    IViewingInviteeResponseModel,
    IViewingRequest,
    IViewingViewModel,
    ViewingViewModel,
} from '@models/viewing/viewing';
import { TranslateService } from '@ngx-translate/core';
import { ConvertDatePipe } from '@pipes/convert-date.pipe';
import { AdvertStoreService } from '@services/advert.store';
import { AnalyticsService } from '@services/analytics.service';
import { UserSettingsService } from '@services/user-settings.service';
import { ViewingsService } from '@services/viewings.service';
import moment, { Moment } from 'moment';
import { Observable } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { UnSubscriptionDirective } from 'src/app/directives/unsubscribe.directive';
import { areOnSameDay, getInitialStartTime } from '../viewing-helpers/common';
import { setupViewingForm } from '../viewing-helpers/viewing-helpers';

export enum ViewingAndConfirmationFlowViewType {
    Loading = 'Loading',
    Error = 'Error',
    Success = 'Success',
    Confirmation = 'Confirmation',
    ViewingInvitation = 'ViewingInvitation',
    Warning = 'Warning',
}

export interface IViewingModalArgs {
    advertId: string;
    prospects: ProspectViewModel[];
    mode: 'ViewingInvitation' | 'Confirmation';
}

interface IAdvertViewModelReadyForViewingFields {
    translationKey: string;
    value: unknown;
}

enum Tabs {
    CREATE_VIEWING = 1,
    SELECT_VIEWING = 0,
}

@Component({
    selector: 'viewing-dialog',
    templateUrl: './viewing-dialog.component.html',
    styleUrls: ['./viewing-dialog.component.less'],
})
export class ViewingDialogComponent extends UnSubscriptionDirective implements OnInit {
    private advertStore = inject(AdvertStoreService);
    private translateService = inject(TranslateService);
    private convertDatePipe = inject(ConvertDatePipe);
    private viewingsService = inject(ViewingsService);
    private gaService = inject(AnalyticsService);
    private userSettingsService = inject(UserSettingsService);

    private readonly changeDetectorRef = inject(ChangeDetectorRef);
    dialogRef = inject<MatDialogRef<ViewingDialogComponent>>(MatDialogRef);
    args = inject<IViewingModalArgs>(MAT_DIALOG_DATA);

    form: UntypedFormGroup;
    currentView: ViewingAndConfirmationFlowViewType;

    private viewingAndConfirmationWasSuccessfully: boolean = false;
    private advert: AdvertViewModel;

    @ViewChild(LoadingIndicatorComponent, { static: true })
    private loadingIndicator: LoadingIndicatorComponent;

    @ViewChild(DownloadIcsComponent, { static: true }) private downloadIcsElement: DownloadIcsComponent;

    selectableViewings: Selectable<ViewingViewModel>[];
    enableIcsDownloadForCreatedViewing: boolean = false;

    selectedTab: Tabs;

    createdViewingId: string;
    errorMessage: string;
    isMobileSafariBrowser: boolean = false;

    get title(): string {
        const titleKey =
            this.args.mode === 'Confirmation'
                ? 'VIEWINGS_VIEW.VIEWING_CONFIRMATION'
                : 'VIEWINGS_VIEW.VIEWING_INVITATION';
        return this.translateService.instant(titleKey);
    }

    get startTimeIsValid(): boolean {
        const now = new Date();

        const date: Date = this.form.get('date').value;
        const startTime: number = this.form.get('startTime').value;

        if (areOnSameDay(date, now)) {
            return startTime >= getInitialStartTime(now);
        }

        return true;
    }

    get endTimeIsValid(): boolean {
        return this.form.get('startTime').value < this.form.get('endTime').value;
    }

    get today(): Moment {
        return moment();
    }

    get showViewingActionButtons(): boolean {
        return (
            !this.isLoading &&
            this.currentView !== 'Success' &&
            this.currentView !== 'Warning' &&
            this.currentView !== 'Error'
        );
    }

    get canSubmit(): boolean {
        return this.selectableViewings?.some((p) => p.isSelected);
    }

    get futureViewings(): Selectable<ViewingViewModel>[] {
        return this.selectableViewings
            ?.filter(
                (e) =>
                    !e.context.isArchived &&
                    !e.context.isViewingCarriedOut &&
                    e.context.status !== 'attendee-confirmation',
            )
            .sort(this.sortByDateAscending);
    }

    get isLoading(): boolean {
        return this.currentView === ViewingAndConfirmationFlowViewType.Loading;
    }

    get showTabs(): boolean {
        return [
            ViewingAndConfirmationFlowViewType.Confirmation,
            ViewingAndConfirmationFlowViewType.ViewingInvitation,
        ].includes(this.currentView);
    }

    get errorText(): string {
        return this.errorMessage;
    }

    get isEmpty(): boolean {
        return this.futureViewings && this.futureViewings.length === 0;
    }

    get successMessage(): string {
        const invitationSuccessMessage = this.translateService.instant('SHARED_COMPONENT.VIEWING_INVITATION_SUCCESS');
        const confirmationSuccessMessage = this.translateService.instant(
            'SHARED_COMPONENT.VIEWING_CONFIRMATION_SUCCESS',
        );

        return this.args.mode === 'Confirmation' ? confirmationSuccessMessage : invitationSuccessMessage;
    }

    get showDownloadIcsButton(): boolean {
        if (this.enableIcsDownloadForCreatedViewing) {
            return this.enableIcsDownloadForCreatedViewing;
        }

        const selectedViewings = this.selectableViewings.filter((e) => e.isSelected);
        return this.currentView === 'Success' && selectedViewings && selectedViewings.length === 1;
    }

    get isAdditionalInfoEngAvailableFor(): boolean {
        return !(this.advert.isCanadianTorontoUnit || this.advert.isBritishUnit || this.advert.isUnitedStatesUnit);
    }

    get canSave(): boolean {
        if (this.selectedTab === Tabs.SELECT_VIEWING) {
            return !this.canSubmit;
        }

        if (this.selectedTab === Tabs.CREATE_VIEWING) {
            return !this.form.valid;
        }

        return false;
    }

    get createViewingActionLabel(): boolean {
        return this.args.mode === 'Confirmation'
            ? this.translateService.instant('VIEWINGS_VIEW.SEND_CONFIRMATION')
            : this.translateService.instant('VIEWINGS_VIEW.SEND_INVITATION');
    }

    get upcomingViewingsLabel(): string {
        return `${this.translateService.instant('VIEWINGS_VIEW.SELECT_VIEWING')} ${
            this.futureViewings?.length > 0 ? this.futureViewings.length : ''
        }`;
    }

    get isAmPmFormat() {
        return TimeHelper.isAmPmFormat();
    }

    get tabIndex(): number {
        if (this.futureViewings?.length > 0) {
            return Tabs.SELECT_VIEWING;
        }
        return Tabs.CREATE_VIEWING;
    }

    ngOnInit(): void {
        this.currentView =
            this.args.mode === 'Confirmation'
                ? ViewingAndConfirmationFlowViewType.Confirmation
                : ViewingAndConfirmationFlowViewType.ViewingInvitation;

        this.loadingIndicator.show();
        this.viewingsService
            .getViewings(this.args.advertId)
            .pipe(
                takeUntil(this.unsubscribe$),
                finalize(() => {
                    this.loadingIndicator.hide();
                }),
            )
            .subscribe(
                (res) => this.gotViewings(res),
                () => this.requestError(),
            );
        this.dialogRef
            .backdropClick()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => this.cancel());

        this.advertStore.advertSubject.pipe(takeUntil(this.unsubscribe$)).subscribe((advert) => {
            this.advert = advert;
            this.checkIsAdvertViewModelReadyForViewing(this.advert);
        });

        this.form = setupViewingForm(this.isAdditionalInfoEngAvailableFor, this.unsubscribe$);
        this.isMobileSafariBrowser = this.userSettingsService.isMobileSafariBrowser();
    }

    isAlreadyConfirmedViewing(viewing: Selectable<ViewingViewModel>): boolean {
        if (!this.args.prospects) {
            return;
        }
        const prospects = this.args.prospects.map((p) => p.id);

        return viewing.context.invitees.some(
            (invitee) => invitee.response === 'confirmed' && prospects.includes(invitee.id),
        );
    }

    cancel(): void {
        this.dialogRef.close(this.viewingAndConfirmationWasSuccessfully);
    }

    updateTabIndex($event): void {
        if (Tabs.SELECT_VIEWING === $event.index) {
            this.selectedTab = Tabs.SELECT_VIEWING;
        } else if (Tabs.CREATE_VIEWING === $event.index) {
            this.selectedTab = Tabs.CREATE_VIEWING;
        }

        this.changeDetectorRef.detectChanges();
    }

    inviteesLabel(viewing: Selectable<ViewingViewModel>): string {
        const label = viewing.context.invitees.length === 1 ? 'VIEWINGS_VIEW.INVITEE' : 'VIEWINGS_VIEW.INVITEES';
        const translated = this.translateService.instant(label);
        return `${viewing.context.invitees.length} ${translated}`;
    }

    isDisabled(viewing: Selectable<ViewingViewModel>): boolean {
        const selectedOnce = this.selectableViewings.some(
            (e) => e.context.id === viewing.context.id && viewing.isSelected,
        );
        if (selectedOnce) {
            return false;
        }

        return this.args.mode === 'Confirmation' && this.selectableViewings.some((e) => e.isSelected);
    }

    toggleSelection(viewing: Selectable<ViewingViewModel>, isSelected: boolean): void {
        viewing.isSelected = isSelected;
    }

    displayViewingDate(viewing: Selectable<ViewingViewModel>): string {
        const day = viewing.context.date.getDay();
        const dayLabel = TimeHelper.geDayLabel(day);
        const translatedDay = this.translateService.instant(dayLabel);
        const date = this.convertDatePipe.transform(viewing.context.date);
        const startTime = TimeHelper.getInputFormattedValue(viewing.context.startTime);
        const endTime = TimeHelper.getInputFormattedValue(viewing.context.endTime);

        return `${translatedDay}, ${date}, ${startTime} - ${endTime}`;
    }

    sendConfirmation(): void {
        this.currentView = ViewingAndConfirmationFlowViewType.Loading;
        this.loadingIndicator.show();

        const advertId = this.args.advertId;
        const viewingId = this.selectableViewings.find((viewing) => viewing.isSelected).context.id;
        const inviteeIds = this.args.prospects.map((p) => p.id);

        const inviteeResponseData: IViewingInviteeResponseModel = {
            operationId: 'confirm',
            viewingId,
            inviteeIds: inviteeIds,
        };

        this.viewingsService
            .replyToInvitation(inviteeResponseData, advertId)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
                (response) => {
                    const { smsSendingResults } = response.data;
                    if (smsSendingResults?.some((r) => r.type !== 'ok')) {
                        const errorMessage = SendSmsErrorMessageHelper.generateSmsSendingErrorMessage(
                            smsSendingResults,
                            this.translateService,
                        );
                        this.confirmWarning(errorMessage);
                        return;
                    }

                    this.confirmSuccess();
                },
                () => this.confirmError(),
            );

        this.gaService.event(GoogleAnalyticsEvents.ViewingConfirmationSent);
    }

    sendViewingInvitation(): void {
        this.currentView = ViewingAndConfirmationFlowViewType.Loading;
        this.loadingIndicator.show();

        const advertId = this.args.advertId;
        const viewingId = this.selectableViewings.filter((v) => v.isSelected).map((e) => e.context.id);
        const invitees = this.args.prospects.map((p) => p.id);

        this.viewingsService
            .inviteToViewing(advertId, viewingId, invitees)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
                () => this.invitationSuccess(),
                () => this.invitationError(),
            );

        this.gaService.event(
            GoogleAnalyticsEvents.ViewingInvitationSent,
            'data',
            `number_of_invited_prospects:${invitees}`,
        );
    }

    downloadViewingCalenderFile(): void {
        const advertId = this.args.advertId;

        if (this.enableIcsDownloadForCreatedViewing) {
            this.downloadIcsElement.downloadEventCalenderFile(advertId, this.createdViewingId);
            return;
        }

        const viewingId = this.selectableViewings.find((viewing) => viewing.isSelected).context.id;
        this.downloadIcsElement.downloadEventCalenderFile(advertId, viewingId);
    }

    save(): void {
        if (this.selectedTab === Tabs.SELECT_VIEWING) {
            if (this.args.mode === 'Confirmation') {
                return this.sendConfirmation();
            }
            if (this.args.mode === 'ViewingInvitation') {
                return this.sendViewingInvitation();
            }
        }

        this.gaService.event(GoogleAnalyticsEvents.ViewingEventCreated);

        if (this.args.mode === ViewingAndConfirmationFlowViewType.Confirmation) {
            this.gaService.event(GoogleAnalyticsEvents.ViewingConfirmationSent);
        } else {
            const viewing = this.readFormData();
            this.gaService.event(
                GoogleAnalyticsEvents.ViewingInvitationSent,
                'data',
                `number_of_invited_prospects:${viewing.invitees.length}`,
            );
        }

        this.currentView = ViewingAndConfirmationFlowViewType.Loading;
        this.loadingIndicator.show();
        this.dialogRef.disableClose = true;

        this.createAndInviteOrConfirm()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
                (response) => {
                    const { smsSendingResults } = response.data;
                    if (smsSendingResults?.some((r) => r.type !== 'ok')) {
                        const errorMessage = SendSmsErrorMessageHelper.generateSmsSendingErrorMessage(
                            smsSendingResults,
                            this.translateService,
                        );
                        this.confirmWarning(errorMessage);
                        return;
                    }

                    this.saveSuccess(response);
                },
                () => this.saveError(),
            );
    }

    private checkIsAdvertViewModelReadyForViewing(advertViewModel: AdvertViewModel): void {
        const advertReadyForViewingFields = this.getAdvertViewModelReadyForViewingFields(advertViewModel);

        const notValidFields = advertReadyForViewingFields.filter(
            (field) =>
                field.value === undefined ||
                field.value === '' ||
                field.value === null ||
                field.value === 'notSelected',
        );
        if (!notValidFields.length) {
            return;
        }

        const notValidFieldsResult: IAdvertViewModelReadyForViewingFields[] = [];
        for (const notValidField of notValidFields) {
            const { translationKey, value } = notValidField;
            notValidFieldsResult.push({ translationKey, value });
        }

        this.currentView = ViewingAndConfirmationFlowViewType.Error;
        this.errorMessage = this.generateAdvertViewModelReadyForViewingErrorMessage(notValidFieldsResult);
    }

    private getAdvertViewModelReadyForViewingFields(
        advertViewModel: AdvertViewModel,
    ): IAdvertViewModelReadyForViewingFields[] {
        const advertisementKeyfacts = getSectionKeyfacts(advertViewModel.keyfacts, 'advertisement-section');
        const streetName = getValueOrUndefined(advertisementKeyfacts, 'streetname');
        const postalCode = getValueOrUndefined(advertisementKeyfacts, 'postalcode');
        const city = getValueOrUndefined(advertisementKeyfacts, 'city');
        const houseNumber = getValueOrUndefined(advertisementKeyfacts, 'housenumber');
        const province = getValueOrUndefined(advertisementKeyfacts, 'province');
        const { contactFirstName, contactLastName, email, phoneNumber } = extractContacts(advertViewModel);
        const { countryCode } = advertViewModel;

        const advertReadyForViewingFields: IAdvertViewModelReadyForViewingFields[] = [
            { translationKey: 'KEY_FACTS.streetName', value: streetName },
            { translationKey: 'KEY_FACTS.postalCode', value: postalCode },
            { translationKey: 'KEY_FACTS.city', value: city },
            { translationKey: 'KEY_FACTS.contactFirstName', value: contactFirstName },
            { translationKey: 'KEY_FACTS.contactLastName', value: contactLastName },
            { translationKey: 'KEY_FACTS.phoneNumber', value: phoneNumber },
            { translationKey: 'KEY_FACTS.email', value: email },
        ];

        if (countryCode === 'DE') {
            advertReadyForViewingFields.push({
                translationKey: 'KEY_FACTS.houseNumber',
                value: houseNumber,
            });
        }

        if (countryCode === 'CA') {
            advertReadyForViewingFields.push({ translationKey: 'KEY_FACTS.province', value: province });
        }

        return advertReadyForViewingFields;
    }

    private createAndInviteOrConfirm(): Observable<IHandleEventCreateInviteAndConfirmResponseBody> {
        const viewing: IViewingViewModel = this.readFormData();
        const advertId = this.args.advertId;
        const {
            id,
            startTime,
            endTime,
            description,
            descriptionEn,
            date,
            meetingPoint,
            numberOfDeletedInvitees,
            totalNumberOfInvitees,
        } = viewing;

        const viewingRequest: IViewingRequest = {
            id,
            startTime,
            endTime,
            description,
            descriptionEn,
            date,
            meetingPoint,
            numberOfDeletedInvitees,
            totalNumberOfInvitees,
            inviteeIds: viewing.invitees.map((i) => i.id),
        };

        if (this.args.mode === ViewingAndConfirmationFlowViewType.Confirmation) {
            return this.viewingsService.createViewingAndConfirm(advertId, viewingRequest);
        }

        return this.viewingsService.createViewingAndInvite(advertId, viewingRequest);
    }

    private readFormData(): IViewingViewModel {
        const date = this.form.value.date?.format('YYYY-MM-DD');

        return {
            date: date,
            description: this.form.value.description,
            descriptionEn: this.form.value.descriptionEn,
            endTime: this.form.value.endTime,
            startTime: this.form.value.startTime,
            invitees: this.args.prospects.map((p) => this.getInvitees(p)),
            meetingPoint: this.form.value.meetingPoint,
            numberOfDeletedInvitees: this.form.value.numberOfDeletedInvitees,
            totalNumberOfInvitees: this.form.value.totalNumberOfInvitees,
        };
    }

    private getInvitees(p: ProspectViewModel): IInviteeViewModel {
        const { id, name, email, phone } = p;
        return { id, name, email, phone, response: null, hasAttended: false };
    }

    private saveSuccess(response: IHandleEventCreateInviteAndConfirmResponseBody): void {
        this.createdViewingId = response.data.eventId;

        this.loadingIndicator.hide();
        this.currentView = ViewingAndConfirmationFlowViewType.Success;
        this.viewingAndConfirmationWasSuccessfully = true;
        this.enableIcsDownloadForCreatedViewing = true;
    }

    private saveError(): void {
        this.updateDialogForError();
    }

    private invitationSuccess(): void {
        this.loadingIndicator.hide();
        this.currentView = ViewingAndConfirmationFlowViewType.Success;
        this.viewingAndConfirmationWasSuccessfully = true;
    }

    private confirmSuccess(): void {
        this.loadingIndicator.hide();
        this.currentView = ViewingAndConfirmationFlowViewType.Success;
        this.viewingAndConfirmationWasSuccessfully = true;
    }

    private confirmWarning(message: string): void {
        this.errorMessage = message;
        this.loadingIndicator.hide();
        this.currentView = ViewingAndConfirmationFlowViewType.Warning;
        this.viewingAndConfirmationWasSuccessfully = true;
    }

    private confirmError(): void {
        this.updateDialogForError();
    }

    private invitationError(): void {
        this.updateDialogForError();
    }

    private updateDialogForError() {
        this.errorMessage = this.translateService.instant('SHARED_COMPONENT.VIEWING_INVITATION_CONFIRMATION_ERROR');

        this.loadingIndicator.hide();
        this.currentView = ViewingAndConfirmationFlowViewType.Error;
    }

    private gotViewings(res: IViewingsResponseBody): void {
        this.selectableViewings = res.data.map((e) => new Selectable(ViewingViewModel.factory(e)));
    }

    private requestError(): void {
        this.currentView = ViewingAndConfirmationFlowViewType.Error;
    }

    private sortByDateAscending(a: Selectable<ViewingViewModel>, b: Selectable<ViewingViewModel>) {
        const aDate = a.context.date;
        const bDate = b.context.date;

        if (aDate < bDate) {
            return -1;
        }
        if (aDate > bDate) {
            return 1;
        }
        return 0;
    }

    private generateAdvertViewModelReadyForViewingErrorMessage(
        advertReadyForViewingFields: IAdvertViewModelReadyForViewingFields[],
    ): string {
        const properties = advertReadyForViewingFields
            .map((field) => `${this.translateService.instant(field.translationKey)}`)
            .join(', ');
        return this.translateService.instant('SHARED_COMPONENT.IS_VIEWING_EVENT_INVITABLE_OR_CONFIRMABLE_ERROR', {
            properties,
        });
    }

    byViewingId(_index: number, item: ViewingViewModel): string {
        return item.id;
    }
}
