import { Component, ElementRef, OnInit, ViewChild, inject } from '@angular/core';
import { LoadingIndicatorComponent } from '@components/shared';
import { getOrientation } from '@helpers/image.helper';
import { FileUploadControl, FileUploadValidators } from '@iplab/ngx-file-upload';
import { MimeType } from '@models/backend/images';
import { IImage, IUploadProgress } from '@models/shared';
import { BehaviorSubject, takeUntil } from 'rxjs';
import { UnSubscriptionDirective } from 'src/app/directives/unsubscribe.directive';

@Component({
    selector: 'image-upload',
    templateUrl: './image-upload.component.html',
    styleUrls: ['./image-upload.component.less'],
})
export class ImageUploadComponent extends UnSubscriptionDirective implements OnInit {
    private el = inject(ElementRef);

    @ViewChild(LoadingIndicatorComponent, { static: true })
    private loadingIndicator: LoadingIndicatorComponent;

    uploadedImages: IImage[] = [];

    hasStartUploading: boolean = false;

    loadingProgress: IUploadProgress[] = [];

    hasAllImageUploadSucceeded: boolean = false;
    hasSomeImageUploadFailed: boolean = false;

    readonly uploadedFiles$: BehaviorSubject<IImage[]> = new BehaviorSubject([]);

    private acceptedMimeTypes: MimeType[] = ['image/jpeg', 'image/png'];

    fileUploadControl = new FileUploadControl(
        { listVisible: false, discardInvalid: true, accept: this.acceptedMimeTypes, multiple: true },
        [FileUploadValidators.accept(this.acceptedMimeTypes), FileUploadValidators.filesLimit(25)],
    );

    ngOnInit(): void {
        this.fileUploadControl.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((files: Array<File>) => {
            if (files.length > this.uploadedImages.length) {
                this.loadingIndicator.show();
            }

            this.uploadedImages = [];
            files.forEach((file) => {
                this.getImage(file);
            });
        });
    }

    triggerUpload(): void {
        const inputFileRef = this.el.nativeElement.querySelector('.upload-input');
        inputFileRef.click();
    }

    hasStartedUploading(hasStartUploading: boolean): void {
        this.hasStartUploading = hasStartUploading;
    }

    uploadCompleted(uploadingProgress: IUploadProgress[]): void {
        if (uploadingProgress?.length > 0) {
            this.loadingProgress = uploadingProgress;
            this.hasSomeImageUploadFailed = uploadingProgress.some((item) => item.uploadStatus === 'failed');
            this.hasAllImageUploadSucceeded = uploadingProgress.every((item) => item.uploadStatus === 'success');
        }
    }

    getImage(file: File): void {
        if (FileReader && file) {
            const fileReader = new FileReader();
            this.uploadedImages = [];
            fileReader.onload = (event) => {
                const result = event.target.result as string;
                const image = new Image();
                image.src = result;

                image.onload = () => {
                    this.uploadedImages.push({
                        name: file.name,
                        orientation: getOrientation(image),
                        size: file.size,
                        image: result,
                    });

                    if (this.fileUploadControl.value.length === this.uploadedImages.length) {
                        this.uploadedFiles$.next(this.uploadedImages);
                        this.loadingIndicator.hide();
                    }
                };
            };
            fileReader.readAsDataURL(file);
        }
    }

    backToNewUpload(): void {
        this.hasSomeImageUploadFailed = false;
        this.hasAllImageUploadSucceeded = false;
        this.fileUploadControl.clear();
    }
}
