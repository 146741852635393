<div id="viewings-view" class="advert-viewings let-mat-dialog fix" *ngIf="viewings">
    <section class="filter" [formGroup]="filterForm">
        <mat-form-field>
            <mat-label>{{ 'SHARED_COMPONENT.DATE' | translate }}</mat-label>
            <input matInput [matDatepicker]="datePicker" autocomplete="off" formControlName="date" />
            <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
            <mat-datepicker #datePicker></mat-datepicker>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'SHARED_COMPONENT.STATUS' | translate }}</mat-label>
            <mat-select formControlName="status">
                <mat-option *ngFor="let status of statuses" [value]="status.value">{{
                    status.label | translate
                }}</mat-option>
            </mat-select>
        </mat-form-field>
    </section>

    <section class="head">
        <article class="subtitle">
            <span>{{ 'VIEWINGS_VIEW.VIEWINGS' | translate }}</span>
            <span *ngIf="futureViewings?.length > 0"> {{ futureViewings.length }}</span>
        </article>
        <button mat-flat-button clickAndKeydown (actionTriggered)="createViewing()">
            <mat-icon>add</mat-icon>
            {{ 'VIEWINGS_VIEW.VIEWING' | translate }}
        </button>
    </section>

    <section class="items" *ngIf="!isEmpty">
        <viewing-item
            *ngFor="let viewing of futureViewings; trackBy: byViewingsId"
            [viewing]="viewing"
            [advertId]="advert.id"
            (viewingShowInvitees)="showInvitees(viewing)"
            (viewingDeleted)="deleted(viewing)"
            (viewingCopied)="copyViewing(viewing)"
            (viewingEdited)="editViewing(viewing)"
            (viewingCalenderFile)="downloadEventCalenderFile(viewing)"
            (refreshViewings)="refreshViewings()"
        ></viewing-item>
    </section>

    <div class="expander" *ngIf="hasArchivedViewings" clickAndKeydown (actionTriggered)="expandArchived()">
        <mat-icon>{{ isArchiveExpanded ? 'expand_less' : 'expand_more' }}</mat-icon>
        <span class="subtitle">
            {{ 'VIEWINGS_VIEW.ARCHIVE' | translate }}
            <span *ngIf="archivedViewings?.length > 0">{{ archivedViewings.length }}</span>
        </span>
    </div>

    <section class="items archived" [class.visible]="isArchiveExpanded" *ngIf="hasArchivedViewings">
        <viewing-item
            *ngFor="let viewing of archivedViewings; trackBy: byViewingsId"
            [viewing]="viewing"
            (viewingShowInvitees)="showInvitees(viewing)"
            (viewingDeleted)="deleted(viewing)"
            (viewingCopied)="copyViewing(viewing)"
        ></viewing-item>
    </section>

    @if (isEmpty) {
        <section class="empty">
            <ak-message-box [type]="'info'">
                {{ 'VIEWINGS_VIEW.NO_VIEWINGS_FOUND' | translate }}
            </ak-message-box>
        </section>
    }
</div>

@if (isForbidden) {
    <div class="empty forbidden">
        <ak-message-box [type]="'warning'">
            {{ 'EVENTS.NOT_ALLOWED' | translate }}
        </ak-message-box>
    </div>
}

<loading-indicator></loading-indicator>

<download-ics></download-ics>
