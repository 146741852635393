<div class="publish-bar" [class.standalone]="isStandaloneDisplayMode" *ngIf="advert">
    <section class="content">
        <div *ngIf="isPreview">
            <button mat-flat-button routerLink="/advert/{{ advert.id }}">
                {{ 'SHARED_COMPONENT.ADVERT' | translate }}
            </button>
            <button mat-flat-button color="primary" clickAndKeydown (actionTriggered)="publish()">
                <mat-icon class="material-icons-outlined">cloud</mat-icon>
                <span class="action-label">{{ 'SHARED_COMPONENT.PUBLISH' | translate }}</span>
            </button>
        </div>

        <div *ngIf="!isPreview">
            <button mat-flat-button [matMenuTriggerFor]="menu" *ngIf="advert.isPublished">
                {{ 'SHARED_COMPONENT.ACTIONS' | translate }}
                <mat-icon class="no-margin-left-and-right" iconPositionEnd>expand_more</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <button class="action-item" mat-menu-item clickAndKeydown (actionTriggered)="toggleReservation()">
                    <mat-icon>{{ advert.isReserved ? 'close' : 'schedule' }}</mat-icon>
                    <span>{{
                        advert.isReserved
                            ? ('SHARED_COMPONENT.CANCEL_RESERVATION' | translate)
                            : ('SHARED_COMPONENT.RESERVE' | translate)
                    }}</span>
                </button>
                <button mat-menu-item clickAndKeydown (actionTriggered)="unpublish()">
                    <mat-icon class="material-icons-outlined">cloud_off</mat-icon>
                    {{ 'SHARED_COMPONENT.UNPUBLISH' | translate }}
                </button>
            </mat-menu>
            <button color="primary" mat-flat-button routerLink="/preview/{{ advert.id }}">
                <mat-icon class="material-icons-outlined">visibility</mat-icon>

                <span class="action-label">{{ 'ADVERT.PREVIEW_BUTTON' | translate }}</span>
            </button>
        </div>
    </section>
</div>
