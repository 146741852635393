import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import {
    IViewingModalArgs,
    ViewingDialogComponent,
} from '@components/advert-viewings/viewing-dialog/viewing-dialog.component';
import { buildEmailSubject } from '@helpers/email-subject.helper';
import { ProspectHelper } from '@helpers/prospect.helper';
import { String } from '@helpers/string.helper';
import { AdvertViewModel } from '@models/advert/advert';
import { AdvertType } from '@models/backend/advert';
import { SupportedCountryCode } from '@models/backend/common';
import { ProspectRejectionReason, ProspectStatus } from '@models/backend/prospects';
import { IProspectsPageResponseBody } from '@models/backend/responses';
import { Selectable } from '@models/common/selectable';
import { GoogleAnalyticsEvents } from '@models/google-analytics/google-analytics-events';
import { ProspectViewModel } from '@models/prospect';
import { TranslateService } from '@ngx-translate/core';
import { AdvertStoreService } from '@services/advert.store';
import { AnalyticsService } from '@services/analytics.service';
import { BrowserWindowService } from '@services/browser-window.service';
import { CountryService } from '@services/country.service';
import { ProspectsService } from '@services/prospects.service';
import { SnackBarService } from '@services/snack-bar.service';
import { UserSettingsService } from '@services/user-settings.service';
import { EMAIL_REGEX } from '@validators/regex';
import { EMPTY, fromEvent } from 'rxjs';
import { catchError, debounceTime, takeUntil } from 'rxjs/operators';
import { UnSubscriptionDirective } from 'src/app/directives/unsubscribe.directive';
import { LoadingIndicatorComponent } from '../shared';
import { ProspectCopyComponent } from './prospect-copy/prospect-copy.component';
import { ProspectCopyModalArgs } from './prospect-copy/types';
import { ProspectDeletionReasonComponent } from './prospect-deletion-reason/prospect-deletion-reason.component';
import { ProspectEditorComponent } from './prospect-editor/prospect-editor.component';
import { ProspectRejectionComponent } from './prospect-rejection/prospect-rejection.component';
import { ProspectStatusAfterEmailComponent } from './prospect-status-after-email/prospect-status-after-email.component';
import { ProspectStatusAfterSmsComponent } from './prospect-status-after-sms/prospect-status-after-sms.component';
import { ProspectStatusComponent } from './prospect-status/prospect-status.component';
import { ProsectRejectionDialogResult, ProspectEditDialogResult } from './types';
import { compareByStatusAndDateOfReceipt } from './utils';

export interface IProspectModalArgs {
    prospects?: ProspectViewModel[];
    advertId: string;
    advertType: AdvertType;
    countryCode?: SupportedCountryCode;
    status?: ProspectStatus;
    defaulProspecttStatus?: ProspectStatus;
}

@Component({
    selector: 'advert-prospects',
    templateUrl: 'advert-prospects.component.html',
    styleUrls: ['advert-prospects.component.less'],
})
export class AdvertProspectsComponent extends UnSubscriptionDirective implements OnInit {
    private prospectsService = inject(ProspectsService);
    private advertStore = inject(AdvertStoreService);
    private router = inject(Router);
    private translateService = inject(TranslateService);
    private countryService = inject(CountryService);
    private browserWindowService = inject(BrowserWindowService);
    private dialog = inject(MatDialog);
    private gaService = inject(AnalyticsService);
    private userSettingsService = inject(UserSettingsService);
    private snackBarService = inject(SnackBarService);

    prospects: Selectable<ProspectViewModel>[];
    isForbidden: boolean = false;
    totalNumberOfElements: number;
    hasAllItemsSelected: boolean = false;

    searchAndFilter: UntypedFormGroup;
    statusOptions: string[];

    private advert: AdvertViewModel;
    private region: string;
    private lastScrollingPosition: number;
    private hasAllProspectsLoaded: boolean = false;
    private currentPage: number = 0;
    private countryCode: SupportedCountryCode;

    @ViewChild(LoadingIndicatorComponent, { static: true })
    private loadingIndicator: LoadingIndicatorComponent;

    get isEmpty(): boolean {
        return this.prospects.length === 0;
    }

    get hasProspectsSelected(): boolean {
        return this.prospects?.some((p) => p.isSelected);
    }

    get selectedProspects(): ProspectViewModel[] {
        return this.prospects.filter((p) => p.isSelected).map((p) => p.context);
    }

    private get isPublishedOnAkelius(): boolean {
        return (
            this.advert.isPublished &&
            this.advert.hasPublicationResults &&
            this.advert.publicationResults.some((p) => p.portalName === 'akelius-web' && p.url)
        );
    }

    // TODO: LA-5065 update with forest types to have the correct translation when LM wants to answer to prospect
    private get translatedEmailTypeSegment(): string {
        let type = '';
        switch (this.advert.type) {
            case AdvertType.Commercial:
                type = 'PROSPECTS.OUR_COMMERCIAL';
                break;
            case AdvertType.Living:
            case AdvertType.ApartmentForSale:
                type = 'PROSPECTS.OUR_APARTMENT';
                break;
            case AdvertType.HuntingArea:
                type = 'PROSPECTS.OUR_HUNTING_AREA';
                break;
            default:
                type = 'PROSPECTS.OUR_PARKING_SPACE';
                break;
        }

        return this.translateService.instant(type);
    }

    ngOnInit() {
        this.router.events.pipe(takeUntil(this.unsubscribe$)).subscribe((e) => {
            if (e instanceof NavigationEnd && this.advert.id) {
                this.loadProspects(this.advert.id);
            }
        });

        this.advertStore.advertSubject.pipe(takeUntil(this.unsubscribe$)).subscribe((advert) => {
            this.advertChanged(advert);
        });

        fromEvent(document, 'scroll')
            .pipe(debounceTime(300), takeUntil(this.unsubscribe$))
            .subscribe(() => this.onScroll());

        this.searchAndFilter = new UntypedFormGroup({
            searchForm: new UntypedFormControl(''),
            hasOnlyBookmarkedProspects: new UntypedFormControl(false),
            prospectStatus: new UntypedFormControl(null),
        });

        this.searchAndFilter.valueChanges.pipe(debounceTime(500), takeUntil(this.unsubscribe$)).subscribe((val) => {
            this.callSearchAndFilter(val);
        });

        this.searchAndFilter
            .get('prospectStatus')
            .valueChanges.pipe(takeUntil(this.unsubscribe$))
            .subscribe((val) => {
                this.gaService.event(
                    GoogleAnalyticsEvents.ProspectsSearched,
                    'data',
                    `flag:prospectStatus,value:${val}`,
                );
            });

        this.searchAndFilter
            .get('hasOnlyBookmarkedProspects')
            .valueChanges.pipe(takeUntil(this.unsubscribe$))
            .subscribe((val) => {
                this.gaService.event(
                    GoogleAnalyticsEvents.ProspectsSearched,
                    'data',
                    `flag:hasOnlyBookmarkedProspects,value:${val}`,
                );
            });

        this.searchAndFilter
            .get('searchForm')
            .valueChanges.pipe(debounceTime(500))
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((val) => {
                this.gaService.event(GoogleAnalyticsEvents.ProspectsSearched, 'data', `keyword:${val}`);
            });

        this.countryCode = this.countryService.getCurrentCountry();
        this.statusOptions = ProspectHelper.getStatusOptions(this.countryCode);
    }

    prospectSelectionChanged(): void {
        this.hasAllItemsSelected = this.prospects.every((p) => p.isSelected);
    }

    writeBulkEmail(): void {
        this.writeEmail(this.selectedProspects);
    }

    writeSms(prospects: ProspectViewModel[]): void {
        const args: IProspectModalArgs = {
            advertId: this.advert.id,
            prospects,
            advertType: this.advert.type,
        };

        const dialog = this.dialog.open(ProspectStatusAfterSmsComponent, { data: args });

        dialog
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((status: ProspectStatus | undefined) => {
                if (!status) {
                    return;
                }

                this.handleOptimisticStatusUpdate(prospects, status);
            });
    }

    writeEmail(prospects: ProspectViewModel[]): void {
        if (prospects === undefined || prospects.length === 0) {
            return;
        }

        const subjectSegment = buildEmailSubject(this.advert, this.translateService).replace('&', '%26');

        const isMobile = this.userSettingsService.isMobileDevice();
        const delimiter = isMobile ? ',' : ';';
        const emailAddressSegment = `${prospects.map((p) => p.email).join(`${delimiter}`)}`.replace('&', '%26');

        const link =
            prospects.length === 1
                ? `mailto:${emailAddressSegment}?subject=${subjectSegment}&body=${this.createBodySegment(prospects)}`
                : `mailto:?subject=${subjectSegment}&bcc=${emailAddressSegment}&body=${this.createBodySegment(
                      prospects,
                  )}`;
        this.browserWindowService.openExternalLink(link);

        const dialog = this.dialog.open(ProspectStatusAfterEmailComponent);
        dialog
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((status: ProspectStatus | undefined) => {
                if (!status) {
                    return;
                }

                this.handleOptimisticStatusUpdate(prospects, status);
            });
    }

    private createBodySegment(prospects: ProspectViewModel[]): string {
        const isBulkEmail = prospects.length > 1;

        const englishSpeakingCountries = ['CA', 'GB', 'CY'];
        const isEnglishSpeakingCountry = englishSpeakingCountries.includes(this.countryCode);

        const useNameSubstitute = isBulkEmail || String.isEmpty(prospects[0].name);

        // greeting for the user
        const greeting = useNameSubstitute
            ? this.translateService.instant('PROSPECTS.GREETING')
            : this.translateService.instant('PROSPECTS.HELLO', { name: prospects[0].name });

        // thank you
        const thankYou = isEnglishSpeakingCountry
            ? this.translateService.instant('PROSPECTS.THANK_EN', { streetName: this.advert.streetName })
            : this.translateService.instant('PROSPECTS.THANK', {
                  type: this.translatedEmailTypeSegment,
                  unitId: this.advert.id,
              });

        // link to unit if it has been published
        let linkSegment = '';
        if (this.isPublishedOnAkelius) {
            const url = this.advert.publicationResults.find((p) => p.portalName === 'akelius-web').url;
            linkSegment = this.translateService.instant('PROSPECTS.LINK_SEGMENT', {
                type: this.translatedEmailTypeSegment,
                link: url,
            });
        }

        // link to virtual viewing if present
        const hasVirtualViewingUrl = !String.isEmpty(this.advert.virtualTourUrl);
        let viewingSegment = '';
        if (hasVirtualViewingUrl) {
            viewingSegment = this.translateService.instant('PROSPECTS.VIRTUAL_TOUR_SEGMENT', {
                type: this.translatedEmailTypeSegment,
                link: this.advert.virtualTourUrl,
            });
        }

        return `${greeting}${thankYou}${linkSegment}${viewingSegment}`.replace('&', '%26'); // when the text contains & it means for the url its a mail parameter and it will not generate the email link
    }

    createProspect(defaultProspectStatus?: ProspectStatus): void {
        const args: IProspectModalArgs = {
            advertId: this.advert.id,
            advertType: this.advert.type,
            status: defaultProspectStatus,
        };

        this.openEditProspectDialog(args);
    }

    setViewingInvitationForProspect(prospect: ProspectViewModel): void {
        this.sendViewingInvitation([prospect]);
    }

    setConfirmationForProspect(prospect: ProspectViewModel): void {
        this.sendConfirmation([prospect]);
    }

    setViewingInvitationForProspects(): void {
        this.sendViewingInvitation(this.selectedProspects);
    }

    setConfirmationForProspects(): void {
        this.sendConfirmation(this.selectedProspects);
    }

    editProspect(prospect: ProspectViewModel): void {
        const args: IProspectModalArgs = {
            advertId: this.advert.id,
            advertType: this.advert.type,
            prospects: [structuredClone(prospect)],
        };
        this.openEditProspectDialog(args);
    }

    private sendViewingInvitation(prospects: ProspectViewModel[]): void {
        const args: IViewingModalArgs = {
            mode: 'ViewingInvitation',
            prospects,
            advertId: this.advert.id,
        };
        const dialog = this.dialog.open(ViewingDialogComponent, {
            data: args,
            panelClass: 'unconstrained-dialog',
        });

        dialog
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((viewingInvitationWasSuccessfull) => {
                if (viewingInvitationWasSuccessfull) {
                    this.callSearchAndFilter(this.searchAndFilter.value);
                }
            });
    }

    private sendConfirmation(prospects: ProspectViewModel[]): void {
        const args: IViewingModalArgs = {
            mode: 'Confirmation',
            prospects,
            advertId: this.advert.id,
        };

        const dialog = this.dialog.open(ViewingDialogComponent, {
            data: args,
            panelClass: 'unconstrained-dialog',
        });

        dialog
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((confirmWasSuccessfull) => {
                if (confirmWasSuccessfull) {
                    this.callSearchAndFilter(this.searchAndFilter.value);
                }
            });
    }

    private openEditProspectDialog(args: IProspectModalArgs): void {
        const dialog = this.dialog.open(ProspectEditorComponent, { data: args, panelClass: 'unconstrained-dialog' });
        dialog
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((dialogResult: ProspectEditDialogResult) => {
                const { prospect, mode } = dialogResult;

                if (!prospect) {
                    return;
                }

                if (mode === 'edit') {
                    this.handleProspectEdit(prospect);
                } else if (mode === 'create') {
                    this.handleProspectCreate(prospect);
                }
            });
    }

    private handleProspectEdit(prospect: ProspectViewModel): void {
        const selectableProspectToUpdate = this.prospects.find((p) => p.context.id === prospect.id);
        const unupdatedProspect = structuredClone(selectableProspectToUpdate.context);

        const { context: prospectContext } = selectableProspectToUpdate;
        const hasStatusChanged = prospectContext.status !== prospect.status;

        prospectContext.name = prospect.name;
        prospectContext.email = prospect.email;
        prospectContext.phone = prospect.phone;
        prospectContext.status = prospect.status;
        prospectContext.message = prospect.message;
        prospectContext.personalIdentityNumber = prospect.personalIdentityNumber;
        prospectContext.marketingChannel = prospect.marketingChannel;
        prospectContext.preferredMoveInDate = prospect.preferredMoveInDate;

        if (hasStatusChanged) {
            this.prospects.sort(compareByStatusAndDateOfReceipt).reverse();
        }

        this.prospectsService
            .updateProspect(this.advert.id, prospect)
            .pipe(
                catchError(() => {
                    selectableProspectToUpdate.context = unupdatedProspect;
                    this.snackBarService.showSnackbar('PROSPECTS.SNACK_BAR_MESSAGE.ERROR', 9000);

                    return EMPTY;
                }),
                takeUntil(this.unsubscribe$),
            )
            .subscribe(() => {
                this.snackBarService.showSnackbar('PROSPECTS.SNACK_BAR_MESSAGE.PROSPECT_UPDATE_SUCCESS');
            });
    }

    private handleProspectCreate(prospect: ProspectViewModel): void {
        this.prospects.unshift(new Selectable(prospect));
        this.totalNumberOfElements++;
        this.advert.numberOfProspectMessages++;

        if (prospect.status !== ProspectStatus.New) {
            this.prospects.sort(compareByStatusAndDateOfReceipt).reverse();
        }

        this.prospectsService
            .createProspect(this.advert.id, prospect)
            .pipe(
                catchError(() => {
                    this.prospects = this.prospects.filter((p) => p.context.id);
                    this.snackBarService.showSnackbar('PROSPECTS.SNACK_BAR_MESSAGE.ERROR', 9000);

                    return EMPTY;
                }),
                takeUntil(this.unsubscribe$),
            )
            .subscribe(({ data: createdProspect }) => {
                const selectableProspectToUpdate = this.prospects.find(
                    (selectableProspect) => selectableProspect.context.id === undefined,
                );
                selectableProspectToUpdate.context.id = createdProspect.id;
                selectableProspectToUpdate.context.dateOfReceipt = new Date(createdProspect.dateOfReceipt);

                this.snackBarService.showSnackbar('PROSPECTS.SNACK_BAR_MESSAGE.PROSPECT_CREATE_SUCCESS');
            });
    }

    deleteProspect(prospect: ProspectViewModel): void {
        this.deleteProspects([prospect]);
    }

    setBulkDelete(): void {
        this.deleteProspects(this.selectedProspects);
    }

    private deleteProspects(prospects: ProspectViewModel[]): void {
        const args: IProspectModalArgs = {
            advertId: this.advert.id,
            prospects: prospects,
            advertType: this.advert.type,
        };

        const dialog = this.dialog.open(ProspectDeletionReasonComponent, { data: args });
        dialog
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((isSuccess) => {
                if (!isSuccess) {
                    return;
                }

                this.callSearchAndFilter(this.searchAndFilter.value);
            });
    }

    rejectProspect(prospect: ProspectViewModel): void {
        this.rejectProspects([prospect]);
    }

    bulkCopyProspect(): void {
        this.copyProspects(this.selectedProspects);
    }

    bulkRejectProspects(): void {
        this.rejectProspects(this.selectedProspects);
    }

    private copyProspects(prospects: ProspectViewModel[]): void {
        const sourceAdvertId = this.advert.id;
        const args: ProspectCopyModalArgs = {
            sourceAdvertId,
            prospects,
        };
        const dialog = this.dialog.open(ProspectCopyComponent, {
            data: args,
            panelClass: 'unconstrained-dialog',
        });
        dialog
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((dialogResult) => {
                if (!dialogResult) {
                    return;
                }
            });
    }

    private rejectProspects(prospects: ProspectViewModel[]): void {
        const args: IProspectModalArgs = {
            advertId: this.advert.id,
            prospects: prospects,
            advertType: this.advert.type,
            countryCode: this.advert.countryCode,
        };

        const dialog = this.dialog.open(ProspectRejectionComponent, { data: args, panelClass: 'unconstrained-dialog' });
        dialog
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((result: ProsectRejectionDialogResult | undefined) => {
                if (!result) {
                    return;
                }

                this.handleProspectRejection(prospects, result);
            });
    }

    private handleProspectRejection(prospects: ProspectViewModel[], result: ProsectRejectionDialogResult): void {
        const { isReserved, shouldDelete, hasOffer } = result;
        const reason =
            isReserved || this.advert.type === AdvertType.ApartmentForSale
                ? ProspectRejectionReason.UnitIsReserved
                : ProspectRejectionReason.UnitIsRented;

        const selectableProspectsBeforeUpdate = structuredClone(this.prospects);
        if (shouldDelete) {
            this.prospects = this.prospects.filter(
                (p) => !prospects.map((prospectToDelete) => prospectToDelete.id).includes(p.context.id),
            );

            this.totalNumberOfElements = this.prospects.length;
            this.advert.numberOfProspectMessages = this.prospects.length;
        } else {
            for (const prospect of prospects) {
                const selectableProspect = this.prospects.find((p) => p.context.id === prospect.id);

                if (reason === ProspectRejectionReason.UnitIsReserved) {
                    selectableProspect.context.status = ProspectStatus.RejectedReservation;
                } else {
                    selectableProspect.context.status = ProspectStatus.Rejected;
                }
            }

            this.prospects.sort(compareByStatusAndDateOfReceipt).reverse();
        }

        const isBulkRejection = prospects && prospects.length > 1;
        const rejectionObs = isBulkRejection
            ? this.prospectsService.bulkRejectProspects(this.advert.id, prospects, shouldDelete, hasOffer, reason)
            : this.prospectsService.rejectProspect(this.advert.id, prospects[0], shouldDelete, hasOffer, reason);

        rejectionObs
            .pipe(
                catchError(() => {
                    this.prospects = selectableProspectsBeforeUpdate;
                    this.snackBarService.showSnackbar('PROSPECTS.SNACK_BAR_MESSAGE.ERROR', 9000);
                    this.selectAllChanged(false);

                    return EMPTY;
                }),
                takeUntil(this.unsubscribe$),
            )
            .subscribe(() => {
                this.snackBarService.showSnackbar('PROSPECTS.SNACK_BAR_MESSAGE.REJECTION_EMAIL_SEND_SUCCESS');

                if (isBulkRejection) {
                    this.gaService.event(
                        GoogleAnalyticsEvents.ProspectsInBulkRejected,
                        'data',
                        `reason:${reason},refer_to_akelius:${hasOffer},delete:${shouldDelete}`,
                    );
                } else {
                    this.gaService.event(
                        GoogleAnalyticsEvents.ProspectRejected,
                        'data',
                        `reason:${reason},refer_to_akelius:${hasOffer},delete:${shouldDelete}`,
                    );
                }

                this.selectAllChanged(false);
            });
    }

    setSingleStatus(prospect: ProspectViewModel): void {
        this.setStatus([prospect]);
    }

    setBulkStatus(): void {
        this.setStatus(this.selectedProspects);
    }

    private setStatus(prospects: ProspectViewModel[]): void {
        const dialog = this.dialog.open(ProspectStatusComponent);
        dialog
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((status: ProspectStatus | null) => {
                if (status) {
                    this.handleOptimisticStatusUpdate(prospects, status);
                }
            });
    }

    selectAllChanged(isSelected: boolean): void {
        this.hasAllItemsSelected = isSelected;
        this.prospects.forEach((p) => (p.isSelected = isSelected && EMAIL_REGEX.test(p.context.email)));
    }

    prospectBookmarkChanged(): void {
        if (this.searchAndFilter.value.hasOnlyBookmarkedProspects) {
            this.callSearchAndFilter(this.searchAndFilter.value);
        }
    }

    private callSearchAndFilter(searchAndFilter: {
        searchForm: string;
        hasOnlyBookmarkedProspects: boolean;
        prospectStatus: string;
    }): void {
        this.loadingIndicator.show();

        this.prospectsService
            .getProspectData(
                this.advert.id,
                searchAndFilter.searchForm,
                searchAndFilter.hasOnlyBookmarkedProspects,
                searchAndFilter.prospectStatus,
            )
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((res) => this.gotProspectData(res));
    }

    private advertChanged(advert: AdvertViewModel): void {
        this.advert = advert;
        this.region = this.advert.region;
        this.isForbidden = false;
        this.loadProspects(advert.id);
    }

    private loadProspects(advertId: string): void {
        this.loadingIndicator.show();
        this.prospectsService
            .getProspectData(advertId)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
                (data) => this.gotProspectData(data),
                (errors) => this.responseEror(errors[0]),
            );
    }

    private gotProspectData(response: IProspectsPageResponseBody): void {
        this.loadingIndicator.hide();
        this.hasAllItemsSelected = false;
        this.prospects = response.data.map((p) => new Selectable(ProspectViewModel.factory(p)));
        this.hasAllProspectsLoaded = response.isLast;
        this.totalNumberOfElements = response.totalElements;
        this.advert.numberOfProspectMessages = response.totalElements;
    }

    private responseEror(error: { status: number }): void {
        this.loadingIndicator.hide();
        this.isForbidden = error.status === 403;
    }

    private onScroll(): void {
        const browserWindow = this.browserWindowService.getWindowObject();
        this.pageScrolled(browserWindow.scrollY - this.lastScrollingPosition);
        this.lastScrollingPosition = browserWindow.scrollY;
    }

    private pageScrolled(delta: number): void {
        const scrolledFromTop = document.documentElement.scrollTop || document.body.scrollTop;

        if (delta <= 0 || this.hasAllProspectsLoaded) {
            // scrolling up or no more results. don't do anything
            return;
        }

        const percentageScrolled = scrolledFromTop / document.documentElement.scrollHeight;
        if (percentageScrolled > 0.4) {
            // user has scrolled more than 80% of the view height
            // load more data

            this.loadingIndicator.show();
            this.currentPage++;
            this.prospectsService
                .getProspectData(
                    this.advert.id,
                    this.searchAndFilter.get('searchForm').value,
                    this.searchAndFilter.get('hasOnlyBookmarkedProspects').value,
                    this.searchAndFilter.get('prospectStatus').value,
                    this.currentPage,
                )
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe((data) => this.gotAdditionalElements(data));
        }
    }

    private gotAdditionalElements(response: IProspectsPageResponseBody): void {
        this.loadingIndicator.hide();
        this.hasAllProspectsLoaded = response.isLast;
        const newProspects = response.data.map((p) => new Selectable(ProspectViewModel.factory(p)));
        this.prospects = [...this.prospects, ...newProspects];
    }

    private handleOptimisticStatusUpdate(prospects: ProspectViewModel[], status: ProspectStatus): void {
        const oldStatusesMap = new Map<string, ProspectStatus>();

        prospects.forEach((prospect) => {
            oldStatusesMap.set(prospect.id, prospect.status);
            prospect.status = status;
        });

        this.prospects.sort(compareByStatusAndDateOfReceipt).reverse();

        this.prospectsService
            .bulkUpdateProspectStatus(this.advert.id, status, prospects)
            .pipe(
                catchError(() => {
                    this.prospects.forEach((prospectSelectable) => {
                        const prospect = prospectSelectable.context;
                        if (oldStatusesMap.has(prospect.id)) {
                            prospect.status = oldStatusesMap.get(prospect.id);
                        }
                    });

                    this.snackBarService.showSnackbar('PROSPECTS.SNACK_BAR_MESSAGE.ERROR', 9000);
                    this.selectAllChanged(false);

                    return EMPTY;
                }),
                takeUntil(this.unsubscribe$),
            )
            .subscribe(() => {
                this.snackBarService.showSnackbar('PROSPECTS.SNACK_BAR_MESSAGE.STATUS_UPDATE_SUCCESS');
                this.selectAllChanged(false);
            });
    }

    isEditable(prospect: Selectable<ProspectViewModel>): boolean {
        return !!prospect.context.id;
    }

    byIndex(index: number): number {
        return index;
    }

    byProspectId(_index: number, prospect: ProspectViewModel): string {
        return prospect.id;
    }
}
