<div
    class="viewing-invitees let-mat-dialog modal-base"
    [ngClass]="{
        safariMobileHeight: isMobileSafariBrowser,
    }"
>
    <section class="head">
        <article class="subtitle">{{ 'VIEWINGS_VIEW.INVITEES' | translate }}</article>
        <button mat-icon-button matDialogClose clickAndKeydown (actionTriggered)="close()">
            <mat-icon>close</mat-icon>
        </button>
    </section>

    @if (showError) {
        <section class="error">
            <ak-message-box [type]="'error'">
                {{ 'SHARED_COMPONENT.VIEWING_INVITATION_CONFIRMATION_ERROR' | translate }}
            </ak-message-box>
        </section>
    }

    @if (showWarning) {
        <section class="error">
            <ak-message-box [type]="'warning'">
                {{ warningText }}
            </ak-message-box>
        </section>
    }

    <loading-indicator></loading-indicator>

    @if (numberOfDeletedInvitees > 0) {
        <ak-message-box class="warning" [type]="'warning'">
            {{ numberOfDeletedInviteesText }}
        </ak-message-box>
    }

    <section class="invitees">
        <free-text-search
            labelText="SHARED_COMPONENT.SEARCH_INVITEES"
            (freeTextSearchEvent)="filterInvitees($event)"
            (clearfreeTextSearchEvent)="clearFreeTextSearch()"
        ></free-text-search>

        @if (!invitees.length) {
            <div class="empty">
                <ak-message-box [type]="'info'">
                    {{ 'PROSPECTS.NO_INVITEE_FOUND' | translate }}
                </ak-message-box>
            </div>
        }

        <div class="invitee" *ngFor="let invitee of invitees; trackBy: byInviteeId">
            <div class="invitee-content">
                <article class="heading">{{ invitee.name }}</article>
                <a clickAndKeydown (actionTriggered)="writeEmail(invitee)" class="link">{{ invitee.email }}</a>
                <phone-number-menu [phoneNumber]="invitee.phone"></phone-number-menu>
            </div>
            <ng-container *ngIf="!shouldShowInviteeStatus">
                <toggle-button
                    *ngIf="!hasAttendeeConfirmation"
                    [disabled]="disabled"
                    [status]="invitee.response"
                    (btnToggleChange)="inviteeResponse($event, invitee.id)"
                ></toggle-button>
            </ng-container>

            <div *ngIf="shouldShowInviteeStatus">
                <preview-invitee-state
                    [invitee]="invitee"
                    [shouldShowInviteeStatus]="isViewingCarriedOut"
                ></preview-invitee-state>
                <mat-slide-toggle
                    *ngIf="hasAttendeeConfirmation"
                    [checked]="invitee.hasAttended"
                    (change)="confirmAttended($event, invitee)"
                    >{{ 'VIEWINGS_VIEW.STATUS.attendee' | translate }}
                </mat-slide-toggle>
            </div>
        </div>
    </section>

    <section class="actions" *ngIf="!hasAttendeeConfirmation">
        <button mat-flat-button matDialogClose clickAndKeydown (actionTriggered)="close()">
            {{ 'SHARED_COMPONENT.CLOSE' | translate }}
        </button>
    </section>
    <section class="actions" *ngIf="hasAttendeeConfirmation">
        <button mat-flat-button matDialogClose clickAndKeydown (actionTriggered)="close()">
            {{ 'SHARED_COMPONENT.SAVE_AND_CLOSE' | translate }}
        </button>
        <button mat-flat-button color="primary" clickAndKeydown (actionTriggered)="submitViewingCarriedOut()">
            {{ 'SHARED_COMPONENT.SUBMIT_VIEWING_CARRIED_OUT' | translate }}
        </button>
    </section>
</div>
