import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild, inject } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { LoadingIndicatorComponent } from '@components/shared';
import { buildEmailSubject } from '@helpers/email-subject.helper';
import { SendSmsErrorMessageHelper } from '@helpers/send-sms-error-message.helper';
import { AdvertViewModel } from '@models/advert/advert';
import { GoogleAnalyticsEvents } from '@models/google-analytics/google-analytics-events';
import { InviteeViewModel } from '@models/invitee';
import { IViewingInviteeResponseModel, InviteeEventResponse, ViewingViewModel } from '@models/viewing/viewing';
import { TranslateService } from '@ngx-translate/core';
import { AdvertStoreService } from '@services/advert.store';
import { AnalyticsService } from '@services/analytics.service';
import { BrowserWindowService } from '@services/browser-window.service';
import { UserSettingsService } from '@services/user-settings.service';
import { ViewingsService } from '@services/viewings.service';
import { EMPTY } from 'rxjs';
import { catchError, first, takeUntil } from 'rxjs/operators';
import { UnSubscriptionDirective } from 'src/app/directives/unsubscribe.directive';

export interface IViewingInviteesModalArgs {
    advert: AdvertViewModel;
    viewing: ViewingViewModel;
}

@Component({
    selector: 'viewing-invitees',
    templateUrl: './viewing-invitees.component.html',
    styleUrls: ['./viewing-invitees.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewingInviteesComponent extends UnSubscriptionDirective implements OnInit {
    dialogRef = inject<MatDialogRef<ViewingInviteesComponent>>(MatDialogRef);
    args = inject<IViewingInviteesModalArgs>(MAT_DIALOG_DATA);
    private browserWindowService = inject(BrowserWindowService);
    private translateService = inject(TranslateService);
    private router = inject(Router);
    private viewingsService = inject(ViewingsService);
    private cd = inject(ChangeDetectorRef);
    private gaService = inject(AnalyticsService);
    private snackBar = inject(MatSnackBar);
    private advertStore = inject(AdvertStoreService);
    private userSettingsService = inject(UserSettingsService);

    @ViewChild(LoadingIndicatorComponent, { static: true })
    private loadingIndicator: LoadingIndicatorComponent;

    disabled = false;
    showError = false;
    showWarning = false;
    warningText: string;
    isMobileSafariBrowser: boolean = false;

    freeTextSearchForm: UntypedFormControl = new UntypedFormControl();

    private _invitees: InviteeViewModel[] = [];

    get invitees(): InviteeViewModel[] {
        return this._invitees;
    }

    set invitees(invitees: InviteeViewModel[]) {
        this._invitees = invitees;
    }

    get hasAttendeeConfirmation(): boolean {
        return this.args.viewing.hasConfirmedAttendees;
    }

    get isViewingCarriedOut(): boolean {
        return this.args.viewing.isViewingCarriedOut;
    }

    get isArchived(): boolean {
        return this.args.viewing.isArchived;
    }

    get shouldShowInviteeStatus(): boolean {
        return this.isArchived || this.isViewingCarriedOut || this.hasAttendeeConfirmation;
    }

    get numberOfDeletedInvitees(): number {
        return this.args.viewing.numberOfDeletedInvitees;
    }

    get numberOfDeletedInviteesText(): number {
        return this.translateService.instant('VIEWINGS_VIEW.NUMBER_OF_DELETED_INVITEES', {
            numberOfDeletedInvitees: this.numberOfDeletedInvitees,
        });
    }

    ngOnInit(): void {
        this.isMobileSafariBrowser = this.userSettingsService.isMobileSafariBrowser();
        this.invitees = this.args.viewing.invitees;

        this.dialogRef
            .backdropClick()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => this.backdropClicked());
    }

    writeEmail(invitee: InviteeViewModel): void {
        if (!invitee) {
            return;
        }

        const subjectSegment = buildEmailSubject(this.args.advert, this.translateService);
        const addressSegment = `${invitee.email}`;

        const link = `mailto:${addressSegment}?subject=${subjectSegment}`;

        this.browserWindowService.openExternalLink(link);
    }

    close(): void {
        this.router.navigate([], { queryParamsHandling: '' });
    }

    confirmAttended(attended: MatSlideToggleChange, invitee: InviteeViewModel): void {
        const { checked } = attended;
        if (checked) {
            this.gaService.event(GoogleAnalyticsEvents.ViewingEventAttendeeConfirmed);
        }

        invitee.hasAttended = checked;

        this.showError = false;
        this.loadingIndicator.show();
        this.viewingsService
            .confirmAttendence(this.args.advert.id, invitee, this.args.viewing.id)
            .pipe(
                first(),
                catchError(() => {
                    this.showError = true;
                    this.hideLoader();
                    return EMPTY;
                }),
                takeUntil(this.unsubscribe$),
            )
            .subscribe(() => {
                this.showError = false;
                this.hideLoader();
            });
    }

    inviteeResponse(action: InviteeEventResponse, inviteeId: string): void {
        if (action === 'decline') {
            this.gaService.event(GoogleAnalyticsEvents.ViewingEventDeclined);
        } else if (action === 'confirm') {
            this.gaService.event(GoogleAnalyticsEvents.ViewingEventConfirmed);
        }

        this.disabled = true;
        this.showError = false;
        this.loadingIndicator.show();

        const inviteeResponseData: IViewingInviteeResponseModel = {
            operationId: action,
            viewingId: this.args.viewing.id,
            inviteeIds: [inviteeId],
        };

        this.viewingsService
            .replyToInvitation(inviteeResponseData, this.args.advert.id)
            .pipe(
                first(),
                catchError(() => {
                    this.showError = true;
                    this.hideLoader();
                    return EMPTY;
                }),
                takeUntil(this.unsubscribe$),
            )
            .subscribe((response) => {
                this.showError = false;
                this.showWarning = false;

                const { smsSendingResults } = response.data;
                if (smsSendingResults?.some((r) => r.type !== 'ok')) {
                    this.warningText = SendSmsErrorMessageHelper.generateSmsSendingErrorMessage(
                        smsSendingResults,
                        this.translateService,
                    );
                    this.showWarning = true;
                    this.hideLoader();
                    return;
                }
                if (action === 'confirm') {
                    const confirmSuccessMessage = this.translateService.instant(
                        'SHARED_COMPONENT.VIEWING_INVITEE_CONFIRMATION_SUCCESS',
                    );
                    this.snackBar.open(confirmSuccessMessage, null, {
                        duration: 3000,
                        verticalPosition: 'bottom',
                        horizontalPosition: 'left',
                    });
                }
                this.hideLoader();
            });
    }

    submitViewingCarriedOut(): void {
        this.gaService.event(GoogleAnalyticsEvents.ViewingEventCarriedOut);

        this.loadingIndicator.show();
        this.showError = false;

        this.viewingsService
            .submitAndConfirmAttendence(this.args.advert.id, this.args.viewing.id)
            .pipe(
                catchError(() => {
                    this.showError = true;
                    this.hideLoader();
                    return EMPTY;
                }),
                takeUntil(this.unsubscribe$),
            )
            .subscribe(() => {
                this.args.advert.numberOfOpenViewings--;
                this.advertStore.update(this.args.advert);
                this.showError = false;
                this.hideLoader();
                this.dialogRef.close();
            });
    }

    filterInvitees(searchInput: string) {
        this.invitees = this.args.viewing.invitees;

        if (!searchInput.length) {
            return;
        }

        const filteredInvitees = this.invitees.filter((invitee) => {
            const nameMatches = invitee.name.toLowerCase().includes(searchInput.toLowerCase());
            const emailMatches = invitee.email.toLowerCase().includes(searchInput.toLowerCase());

            return nameMatches || emailMatches;
        });

        if (!filteredInvitees.length) {
            this.invitees = [];
            return;
        }

        this.invitees = filteredInvitees;
    }

    clearFreeTextSearch(): void {
        this.invitees = this.args.viewing.invitees;
    }

    private hideLoader(): void {
        this.loadingIndicator.hide();
        this.disabled = false;
        this.cd.markForCheck();
    }

    private backdropClicked(): void {
        this.router.navigate([], { queryParamsHandling: '' });
    }

    byInviteeId(_index: number, invitee: InviteeViewModel): string {
        return invitee.id;
    }
}
