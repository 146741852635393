<div class="advert-details-section images" *ngIf="advert" id="images-section" [class.disabled]="isLoading">
    <section class="header">
        <span class="subtitle">{{ 'SHARED_COMPONENT.ADVERT_IMAGES' | translate }}</span>
        <i class="material-icons validation-icon" [ngClass]="isValid ? 'valid' : 'invalid'">{{ validationIcon }}</i>
        <button
            mat-icon-button
            clickAndKeydown
            (actionTriggered)="navigateToAdvert()"
            *ngIf="isPreview"
            matTooltip="{{ 'SHARED_COMPONENT.EDIT' | translate }}"
        >
            <mat-icon fontSet="material-icons-outlined">create</mat-icon>
        </button>
        <div class="loader" *ngIf="isLoading">
            <loading-indicator [isPermanent]="true" [isEmbedded]="true" [isSmall]="true"></loading-indicator>
        </div>
    </section>

    <multiple-messages-box
        *ngIf="isMultipleMessageBoxVisible"
        [(validationMessages)]="validationMessages"
    ></multiple-messages-box>

    <section cdkDropListGroup class="selected-images" *ngIf="advert.hasImages">
        <div
            class="drag-parent"
            *ngFor="let image of advert.images; trackBy: byAdvertId; let first = first; let i = index"
            cdkDropList
            [cdkDropListOrientation]="dragOrientation"
            [cdkDropListData]="i"
            (cdkDropListDropped)="imageDropped()"
        >
            <div
                class="image"
                [class.preview]="isPreview"
                cdkDrag
                [cdkDragDisabled]="isPreview"
                [cdkDragData]="i"
                (cdkDragEntered)="imageEntered($event)"
            >
                <aside
                    class="head"
                    [loadImage]="image.smallUrl"
                    [isPortrait]="image.isPortrait"
                    clickAndKeydown
                    (actionTriggered)="zoomInSelectedImage(image)"
                    (onkeydown)="zoomInSelectedImage(image)"
                    aria-label="image section"
                ></aside>
                <aside class="content" aria-label="image content">
                    <div>
                        <mat-chip-set *ngIf="first || image.isExample">
                            <mat-chip color="primary" *ngIf="first">{{
                                'SHARED_COMPONENT.TITLE_IMAGE' | translate
                            }}</mat-chip>
                            <mat-chip *ngIf="image.hasUnitCodeForExampleImage">{{ image.belongsToUnit }}</mat-chip>
                            <mat-chip color="primary" *ngIf="image.isExample">{{
                                'SHARED_COMPONENT.EXAMPLE_IMAGE' | translate
                            }}</mat-chip>
                        </mat-chip-set>
                        <mat-chip-set>
                            <mat-chip
                                ><span class="trim">{{ image.tagText | translate }}</span></mat-chip
                            >
                        </mat-chip-set>
                    </div>
                    <section class="actions" *ngIf="!isPreview">
                        <mat-icon class="drag" cdkDragHandle>drag_indicator</mat-icon>
                        <div>
                            <button
                                mat-icon-button
                                clickAndKeydown
                                (actionTriggered)="zoomInSelectedImage(image)"
                                matTooltip="{{ 'SHARED_COMPONENT.FULL_SCREEN' | translate }}"
                            >
                                <mat-icon>crop_free</mat-icon>
                            </button>
                            <button
                                mat-icon-button
                                clickAndKeydown
                                (actionTriggered)="removeImage(image)"
                                matTooltip="{{ 'SHARED_COMPONENT.DELETE' | translate }}"
                            >
                                <mat-icon>delete_outlined</mat-icon>
                            </button>
                        </div>
                    </section>
                </aside>
            </div>
        </div>
    </section>

    <section class="upload" *ngIf="!isPreview">
        <button mat-flat-button [matMenuTriggerFor]="menu">
            {{ 'SHARED_COMPONENT.UPLOAD_BUTTON' | translate }}
            <mat-icon class="no-margin-left-and-right" iconPositionEnd>expand_more</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
            <button
                *ngFor="let menu of uploadImageMenuItems; trackBy: byIndex"
                mat-menu-item
                clickAndKeydown
                (actionTriggered)="uploadImages(menu.id)"
            >
                {{ menu.label | translate }}
            </button>
        </mat-menu>

        <button mat-flat-button clickAndKeydown (actionTriggered)="showGallery()">
            <mat-icon>add</mat-icon>
            {{ 'SHARED_COMPONENT.ADD_IMAGE' | translate }}
        </button>

        <button mat-flat-button clickAndKeydown (actionTriggered)="refreshAdvert()">
            <mat-icon>autorenew</mat-icon>
            {{ 'SHARED_COMPONENT.REFRESH' | translate }}
        </button>
    </section>
</div>

<div class="zoom-image" *ngIf="isZoomImageOpen && currentZoomImage">
    <div
        class="backdrop"
        *ngIf="isZoomImageOpen"
        clickAndKeydown
        (actionTriggered)="isZoomImageOpen = false"
        (onkeydown)="isZoomImageOpen = false"
    ></div>
    <div class="image" [ngStyle]="currentZoomImage.originalImageStyle"></div>

    <button class="close" mat-flat-button clickAndKeydown (actionTriggered)="isZoomImageOpen = false">
        <i class="material-icons">close</i>
    </button>
    <button
        class="navigate previous"
        mat-flat-button
        clickAndKeydown
        (actionTriggered)="previousImage()"
        *ngIf="hasZoomNavigation"
    >
        <i class="material-icons">chevron_left</i>
    </button>
    <button
        class="navigate next"
        mat-flat-button
        clickAndKeydown
        (actionTriggered)="nextImage()"
        *ngIf="hasZoomNavigation"
    >
        <i class="material-icons">chevron_right</i>
    </button>
</div>
