<div class="download-expose">
    <section class="head">
        <article class="subtitle">{{ 'SHARED_COMPONENT.EXPOSE_DOWNLOAD' | translate }}</article>
        <button mat-icon-button mat-dialog-close [disabled]="isLoading">
            <mat-icon>close</mat-icon>
        </button>
    </section>

    <section class="view" [ngSwitch]="currentViewType">
        <div *ngSwitchCase="0">
            <mat-form-field>
                <mat-label>{{ 'SHARED_COMPONENT.LANGUAGE' | translate }}</mat-label>
                <mat-select [(ngModel)]="selectedCultureInfo">
                    <mat-option [value]="'en-US'">{{ 'SHARED_COMPONENT.LANG_EN' | translate }}</mat-option>
                    <mat-option [value]="'en-GB'"
                        >{{ 'SHARED_COMPONENT.LANG_EN' | translate }},
                        {{ 'SHARED_COMPONENT.UNITED_KINGDOM' | translate }}</mat-option
                    >
                    <mat-option [value]="'fr-FR'">{{ 'SHARED_COMPONENT.LANG_FR' | translate }}</mat-option>
                    <mat-option [value]="'de-DE'">{{ 'SHARED_COMPONENT.LANG_DE' | translate }}</mat-option>
                    <mat-option [value]="'sv-SE'">{{ 'SHARED_COMPONENT.LANG_SV' | translate }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="loading" *ngSwitchCase="1">
            <article>{{ 'SHARED_COMPONENT.EXPOSE_DOWNLOAD_MESSAGE' | translate }}</article>
            <loading-indicator [isPermanent]="true"></loading-indicator>
        </div>

        <div class="done" *ngSwitchCase="2">
            <ak-message-box [type]="'success'">
                {{ 'SHARED_COMPONENT.EXPOSE_DOWNLOAD_SUCCESS' | translate }}
            </ak-message-box>
        </div>
        <div class="done" *ngSwitchCase="3">
            <ak-message-box [type]="'error'">
                {{ 'SHARED_COMPONENT.EXPOSE_DOWNLOAD_ERROR' | translate }}
            </ak-message-box>
        </div>
    </section>

    <section class="actions">
        <button *ngIf="currentViewType !== 1" mat-flat-button mat-dialog-close>
            {{ 'SHARED_COMPONENT.CLOSE' | translate }}
        </button>
        <button
            *ngIf="currentViewType === 0"
            color="primary"
            mat-flat-button
            clickAndKeydown
            (actionTriggered)="languageSelected()"
        >
            {{ 'SHARED_COMPONENT.DOWNLOAD' | translate }}
        </button>
    </section>

    <a style="display: none" #downloadLink></a>
</div>
