import { Injectable } from '@angular/core';
import { SupportedCountryCode, SupportedCultureInfo } from '@models/backend/common';

@Injectable({ providedIn: 'root' })
export class CountryService {
    getCurrentCountry(): SupportedCountryCode {
        return (localStorage.getItem('countryCode') as SupportedCountryCode) || 'GB';
    }

    getCurrentCultureInfo(): SupportedCultureInfo {
        return (localStorage.getItem('userCultureInfo') as SupportedCultureInfo) || 'en-GB';
    }

    isCyprus(): boolean {
        return this.getCurrentCountry() === 'CY';
    }

    isGermany(): boolean {
        return this.getCurrentCountry() === 'DE';
    }

    isUnitedStates(): boolean {
        return this.getCurrentCountry() === 'US';
    }

    isFrance(): boolean {
        return this.getCurrentCountry() === 'FR';
    }

    isGreatBritain(): boolean {
        return this.getCurrentCountry() === 'GB';
    }

    isSweden(): boolean {
        return this.getCurrentCountry() === 'SE';
    }

    isCanada(): boolean {
        return this.getCurrentCountry() === 'CA';
    }
}
