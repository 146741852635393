import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { IAutocompleteData } from '@components/shared/autocomplete/autocomplete';
import { AdvertViewModel } from '@models/advert/advert';
import { IAdvertsPageResponseBody, ITeaserFilterResponseBody } from '@models/backend/responses';
import { ITeaserFilter } from '@models/backend/teaser-filter';
import { ITeaserFilterOptions } from '@models/common/teaser-filter-options';
import { TranslateService } from '@ngx-translate/core';
import { AdvertService } from '@services/advert.service';
import { BrowserWindowService } from '@services/browser-window.service';
import { UserSettingsService } from '@services/user-settings.service';
import { fromEvent } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { UnSubscriptionDirective } from 'src/app/directives/unsubscribe.directive';
import { LoadingIndicatorComponent } from '../shared';

@Component({
    selector: 'teaser-list',
    templateUrl: 'teaser-list.component.html',
    styleUrls: ['teaser-list.component.less'],
})
export class TeaserListComponent extends UnSubscriptionDirective implements OnInit {
    private advertService = inject(AdvertService);
    private browserWindowService = inject(BrowserWindowService);
    private translateService = inject(TranslateService);
    private userSettingsService = inject(UserSettingsService);

    adverts: AdvertViewModel[];
    totalNumberOfElements: number;
    teaserFilterData: ITeaserFilter = {
        contacts: [],
        regions: [],
    };

    private filterOptions: ITeaserFilterOptions = {};
    private lastScrollingPosition: number;
    private hasAllTeasersLoaded = false;
    private pageNumber = 0;

    showUnitListFilter = true;

    @ViewChild(LoadingIndicatorComponent, { static: true })
    private loadingIndicator: LoadingIndicatorComponent;

    ngOnInit(): void {
        this.lastScrollingPosition = this.browserWindowService.getWindowObject().scrollY;

        if (this.userSettingsService.isMobileDevice()) {
            this.showUnitListFilter = false;
        }

        fromEvent(document, 'scroll')
            .pipe(debounceTime(300), takeUntil(this.unsubscribe$))
            .subscribe(() => this.onScroll());

        this.advertService
            .getTeaserFilterData()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((response) => this.gotTeaserFilterData(response));
        this.loadingIndicator.show();
    }

    private gotTeaserFilterData(response: ITeaserFilterResponseBody): void {
        this.teaserFilterData = {
            regions: response.data.regions,
            contacts: response.data.contacts.map(
                (c): IAutocompleteData => ({
                    id: c.email,
                    label: `${c.contactFirstName} ${c.contactLastName}`,
                }),
            ),
        };
        this.loadingIndicator.hide();
    }

    onFilterChanged(filterOptions?: ITeaserFilterOptions): void {
        if (filterOptions) {
            this.filterOptions = filterOptions;
            // reset page number when receiving new filter data
            this.pageNumber = 0;
        }

        this.loadingIndicator.show();
        this.advertService
            .getAdvertTeasers(this.filterOptions)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((data) => this.gotFilteredTeasers(data));
    }

    unpublishAdvertEvent(): void {
        // reset filter after unpublish event
        this.onFilterChanged();
    }

    toggleUnitListFilter(): void {
        this.showUnitListFilter = !this.showUnitListFilter;
    }

    private gotFilteredTeasers(teaserResponse: IAdvertsPageResponseBody): void {
        this.adverts = teaserResponse.data.map((_) => AdvertViewModel.factory(_));
        this.totalNumberOfElements = teaserResponse.totalElements;
        this.hasAllTeasersLoaded = teaserResponse.isLast;
        this.loadingIndicator.hide();
    }

    private onScroll(): void {
        const browserWindow = this.browserWindowService.getWindowObject();
        this.pageScrolled(browserWindow.scrollY - this.lastScrollingPosition);
        this.lastScrollingPosition = browserWindow.scrollY;
    }

    private pageScrolled(delta: number): void {
        if (delta <= 0 || this.hasAllTeasersLoaded) {
            return;
        }

        const scrolledFromTop = document.documentElement.scrollTop || document.body.scrollTop;
        const percentageScrolled = scrolledFromTop / document.documentElement.scrollHeight;
        if (percentageScrolled > 0.5) {
            // user has scrolled more than 50% of the view height
            // load more data
            this.loadingIndicator.show();
            this.pageNumber++;
            this.advertService
                .getAdvertTeasers(this.filterOptions, this.pageNumber)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe((data) => this.appendDataAfterScroll(data));
        }
    }

    private appendDataAfterScroll(teaserResponse: IAdvertsPageResponseBody): void {
        this.loadingIndicator.hide();
        if (teaserResponse.totalElements === 0) {
            return;
        }

        const newAdverts = teaserResponse.data.map((_) => AdvertViewModel.factory(_));
        this.adverts = [...this.adverts, ...newAdverts];
        this.hasAllTeasersLoaded = teaserResponse.isLast;
    }

    byAdvertId(_index: number, advert: AdvertViewModel): string {
        return advert.id;
    }
}
