<ng-template #infoTemplate>
    <ak-message-box [type]="'info'">{{ 'IMAGE_UPLOAD_RESPONSE.uploadInProgress' | translate }}</ak-message-box>
</ng-template>

@if (hasSomeImageUploadFailed && !hasStartUploading) {
    <ak-message-box [type]="'error'">{{ 'IMAGE_UPLOAD_RESPONSE.failed' | translate }}</ak-message-box>
}

@if (hasAllImageUploadSucceeded && !hasStartUploading) {
    <ak-message-box [type]="'success'">{{ 'IMAGE_UPLOAD_RESPONSE.success' | translate }}</ak-message-box>
}

<ng-container *ngIf="!hasSomeImageUploadFailed && !hasAllImageUploadSucceeded">
    <ng-container *ngIf="!hasStartUploading; else infoTemplate">
        <file-upload
            clickAndKeydown
            (actionTriggered)="triggerUpload()"
            [animation]="false"
            [control]="fileUploadControl"
        >
            <ng-template let-isFileDragDropAvailable="isFileDragDropAvailable" #placeholder>
                <file-upload-drop-zone>
                    <ng-container *ngIf="isFileDragDropAvailable; else isNotDragDropAvailable">
                        {{ 'SHARED_COMPONENT.UPLOAD_LABEL' | translate }}
                    </ng-container>
                </file-upload-drop-zone>
            </ng-template>
        </file-upload>
        <span class="max-upload">{{ fileUploadControl.size }} / 25</span>
    </ng-container>
</ng-container>

<div class="loader">
    <loading-indicator></loading-indicator>
</div>

<preview-uploaded-image
    [uploadedImages$]="uploadedFiles$"
    [fileUploadControl]="fileUploadControl"
    (startUploadingEvent)="hasStartedUploading($event)"
    (uploadCompletedEvent)="uploadCompleted($event)"
    (backToUploadEvent)="backToNewUpload()"
></preview-uploaded-image>
