<div class="teaser-filter" *ngIf="isDataAndFormReady" [formGroup]="form">
    <aside class="filter-types">
        <div class="option" [class.full]="!showUnitListFilter && isMobile">
            <!-- Todo:: LA-5725 -->
            <unit-search
                [formControl]="freeTextSearchForm"
                (clearUnitSearchFormEvent)="clearUnitSearchForm()"
                (unitCodeSearchCleanedEvent)="onUnitCodeSearchCleaned()"
            />
        </div>

        <ng-container *ngIf="showUnitListFilter">
            <div class="option">
                <autocomplete
                    highlightActiveFormControl
                    [translateLabels]="false"
                    [isRequired]="false"
                    [showLoading]="showUnitCodeSearchLoader"
                    [useHttp]="true"
                    [formControl]="unitCodeForm"
                    placeholder="{{ 'SHARED_COMPONENT.UNIT_NUMBER' | translate }}"
                    [options]="unitCodes"
                    (optionSelected)="onUnitCodeSelection($event)"
                    (resetClicked)="onUnitCodeSearchCleaned()"
                >
                </autocomplete>
            </div>

            <div class="option">
                <autocomplete
                    highlightActiveFormControl
                    [translateLabels]="false"
                    [isRequired]="false"
                    [formControl]="assignedContactForm"
                    placeholder="{{ 'SHARED_COMPONENT.LETTING_MANAGER' | translate }}"
                    [options]="filterData.contacts"
                    (optionSelected)="onOptionSelected($event)"
                    (resetClicked)="onContactCleaned()"
                >
                </autocomplete>
            </div>

            <div class="option">
                <street-search
                    [formControl]="streetSearchForm"
                    (streetSelectionEvent)="onStreetSelection($event)"
                    (streetSelectionCleardEvent)="onStreetSearchCleaned()"
                />
            </div>
        </ng-container>
    </aside>

    <ng-container *ngIf="showUnitListFilter">
        <aside class="filter-types">
            <div class="option">
                <mat-form-field>
                    <mat-label>{{ 'SHARED_COMPONENT.REGION' | translate }}</mat-label>
                    <mat-select formControlName="region" highlightActiveFormControl>
                        <mat-option [value]="null">{{ 'SHARED_COMPONENT.ALL' | translate }}</mat-option>
                        <mat-option *ngFor="let region of filterData.regions; trackBy: byIndex" [value]="region.name">{{
                            region.name
                        }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="option">
                <mat-form-field>
                    <mat-label>{{ 'SHARED_COMPONENT.BRANCH' | translate }}</mat-label>
                    <mat-select formControlName="branch" highlightActiveFormControl>
                        <mat-option [value]="null">{{ 'SHARED_COMPONENT.ALL' | translate }}</mat-option>
                        <mat-option *ngFor="let branch of branches; trackBy: byIndex" [value]="branch.name">{{
                            branch.name
                        }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="option">
                <mat-form-field>
                    <mat-label>{{ 'SHARED_COMPONENT.ADVERT_PURPOSE' | translate }}</mat-label>
                    <mat-select formControlName="advertType" highlightActiveFormControl>
                        <mat-option [value]="null">{{ 'SHARED_COMPONENT.ALL' | translate }}</mat-option>
                        <mat-option *ngFor="let type of unitType" [value]="type.value">{{
                            type.key | translate
                        }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="option">
                <mat-form-field>
                    <mat-label>{{ 'SHARED_COMPONENT.ADVERT_STATUS' | translate }}</mat-label>
                    <mat-select formControlName="status" highlightActiveFormControl>
                        <mat-option [value]="null">{{ 'SHARED_COMPONENT.ALL' | translate }}</mat-option>
                        <mat-option [value]="'published'">{{ 'SHARED_COMPONENT.PUBLISHED' | translate }}</mat-option>
                        <mat-option [value]="'unpublished'">{{
                            'SHARED_COMPONENT.UNPUBLISHED' | translate
                        }}</mat-option>
                        <mat-option [value]="'bookmarked'">{{ 'SHARED_COMPONENT.BOOKMARKED' | translate }}</mat-option>
                        <mat-option [value]="'hasProspects'">{{
                            'SHARED_COMPONENT.HAS_PROSPECTS' | translate
                        }}</mat-option>
                        <mat-option [value]="'reserved'">{{ 'SHARED_COMPONENT.RESERVED' | translate }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </aside>

        <aside class="filter-types">
            <div class="option" *ngIf="isVacancyAndLettableDateEnabled" data-test="vacancy-status-field">
                <mat-form-field>
                    <mat-label>{{ 'SHARED_COMPONENT.UNIT_STATUS' | translate }}</mat-label>
                    <mat-select formControlName="vacancyStatuses" multiple highlightActiveFormControl>
                        <mat-option value="vacant">
                            <mat-chip class="red">{{ 'SHARED_COMPONENT.VACANT' | translate }}</mat-chip>
                        </mat-option>
                        <mat-option value="notice">
                            <mat-chip class="yellow">{{ 'SHARED_COMPONENT.NOTICE' | translate }}</mat-chip>
                        </mat-option>
                        <mat-option value="occupied">
                            <mat-chip class="green">{{ 'SHARED_COMPONENT.OCCUPIED' | translate }}</mat-chip>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="option">
                <mat-form-field>
                    <mat-label>{{ 'SHARED_COMPONENT.SORT_BY' | translate }}</mat-label>
                    <mat-select formControlName="sortBy" highlightActiveFormControl>
                        <mat-option [value]="'costcenter'">{{
                            'SHARED_COMPONENT.PROFIT_CENTER' | translate
                        }}</mat-option>
                        <mat-option [value]="'publishdate'">{{
                            'SHARED_COMPONENT.PUBLISH_DATE' | translate
                        }}</mat-option>
                        <mat-option [value]="'moveoutdate'">{{
                            'SHARED_COMPONENT.MOVE_OUT_DATE' | translate
                        }}</mat-option>
                        <mat-option
                            *ngIf="isVacancyAndLettableDateEnabled"
                            [value]="'lettabledate'"
                            data-test="lettable-date"
                            >{{ 'SHARED_COMPONENT.LETTABLE_DATE' | translate }}</mat-option
                        >
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="option direction">
                <mat-radio-group formControlName="sortDirection">
                    <mat-radio-button [value]="'ASC'">{{ 'SHARED_COMPONENT.ASCENDING' | translate }}</mat-radio-button>
                    <mat-radio-button [value]="'DESC'">{{
                        'SHARED_COMPONENT.DESCENDING' | translate
                    }}</mat-radio-button>
                </mat-radio-group>
            </div>
            <!-- should be empty to fill 4 column  -->
            <div class="option" *ngIf="!isVacancyAndLettableDateEnabled"></div>
            <div class="option clear-btn">
                <button mat-flat-button clickAndKeydown (actionTriggered)="resetFilter()">
                    {{ 'SHARED_COMPONENT.CLEAR_FILTER' | translate }}
                </button>
            </div>
        </aside>
    </ng-container>
</div>
