import { MessageBoxModule } from '@akelius/angular-ui-kit';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CdkConnectedOverlay, OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule, inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MAT_BUTTON_TOGGLE_DEFAULT_OPTIONS, MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table'; // Import MatTableModule
import { MatTabsModule } from '@angular/material/tabs';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule, SwRegistrationOptions } from '@angular/service-worker';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { AdvertEventItemComponent } from '@components/advert-event-history/advert-event-item/advert-event-item.component';
import { ViewingEventItemComponent } from '@components/advert-event-history/viewing-event-item/viewing-event-item.component';
import { ProspectCopyComponent } from '@components/advert-prospects/prospect-copy/prospect-copy.component';
import { ProspectViewingActionsComponent } from '@components/advert-prospects/prospect-viewing-actions/prospect-viewing-actions.component';
import { ViewingDialogComponent } from '@components/advert-viewings/viewing-dialog/viewing-dialog.component';
import { ViewingEditorComponent } from '@components/advert-viewings/viewing-editor/viewing-editor.component';
import { PreviewInviteeStateComponent } from '@components/advert-viewings/viewing-invitees/preview-invitee-state/preview-invitee-state.component';
import { ViewingInviteesComponent } from '@components/advert-viewings/viewing-invitees/viewing-invitees.component';
import { ViewingItemComponent } from '@components/advert-viewings/viewing-item/viewing-item.component';
import { AdvertsComponent } from '@components/dashboard/sections/adverts/adverts.component';
import { AdvertStatusComponent } from '@components/dashboard/sections/adverts/category/advert-status/advert-status.component';
import { CategoryComponent } from '@components/dashboard/sections/adverts/category/category.component';
import { GeneralInquiriesComponent } from '@components/dashboard/sections/general-inquiries/general-inquiries.component';
import { GeneralInquiryCopyComponent } from '@components/dashboard/sections/general-inquiries/general-inquiry-copy/general-inquiry-copy.component';
import { GeneralInquiryDeletionComponent } from '@components/dashboard/sections/general-inquiries/general-inquiry-deletion/general-inquiry-deletion.component';
import { GeneralInquiryEditorComponent } from '@components/dashboard/sections/general-inquiries/general-inquiry-editor/general-inquiry-editor.component';
import { GeneralInquiryItemComponent } from '@components/dashboard/sections/general-inquiries/general-inquiry-item/general-inquiry-item.component';
import { GeneralInquiryStatusAfterEmailComponent } from '@components/dashboard/sections/general-inquiries/general-inquiry-status-after-email/general-inquiry-status-after-email.component';
import { GeneralInquiryStatusComponent } from '@components/dashboard/sections/general-inquiries/general-inquiry-status/general-inquiry-status.component';
import { InfinityScrollingDirective } from '@components/rotation/infinity-scrolling.directive';
import { CopyAdvertListComponent } from '@components/shared/advert-copy/copy-advert-list/copy-advert-list.component';
import { CopyFilterComponent } from '@components/shared/advert-copy/copy-filter/copy-filter.component';
import { PhoneNumberMenuComponent } from '@components/shared/phone-number-menu/phone-number-menu.component';
import { ProspectFormComponent } from '@components/shared/prospect-form/prospect-form.component';
import { HuntingAreaStatsComponent } from '@components/shared/teaser-stats/hunting-area-stats/hunting-area-stats.component';
import { FileUploadModule } from '@iplab/ngx-file-upload';
import { ImageUploadComponent } from '@modules/image-upload/image-upload/image-upload.component';
import { LeavePageDialogComponent } from '@modules/image-upload/leave-page-dialog/leave-page-dialog.component';
import { PreviewUploadedImageComponent } from '@modules/image-upload/preview-uploaded-image/preview-uploaded-image.component';
import { AppMissingTranslationHandler } from '@modules/sentry/missing-translation.interceptor';
import { SentryModule } from '@modules/sentry/sentry.module';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BytesPipe } from '@pipes/bytes.pipe';
import { ConvertDatePipe } from '@pipes/convert-date.pipe';
import { ConvertTimePipe } from '@pipes/convert-time.pipe';
import { SanitazedPhoneNumberPipe } from '@pipes/sanitazed-phone-number.pipe';
import { AppUpdateService } from '@services/app-update.service';
import { EnvironmentService } from '@services/environment.service';
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';
import { environment } from '../environments/environment';
import { MomentUtcDateAdapter } from './adapter/moment-utc-adapter';
import { AppComponent } from './app.component';
import { AdvertDetailsHeaderComponent } from './components/advert-details-header/advert-details-header.component';
import { PublishedPortalsComponent } from './components/advert-details-header/published-portals/published-portals.component';
import { AdvertDetailsComponent } from './components/advert-details/advert-details.component';
import { AdvertEventHistoryComponent } from './components/advert-event-history/advert-event-history.component';
import { AdvertImageUploadComponent } from './components/advert-image-upload/advert-image-upload.component';
import { AdvertPreviewComponent } from './components/advert-preview/advert-preview.component';
import { AdvertProspectsComponent } from './components/advert-prospects/advert-prospects.component';
import { ProspectDeletionReasonComponent } from './components/advert-prospects/prospect-deletion-reason/prospect-deletion-reason.component';
import { ProspectEditorComponent } from './components/advert-prospects/prospect-editor/prospect-editor.component';
import { ProspectItemComponent } from './components/advert-prospects/prospect-item/prospect-item.component';
import { ProspectRejectionComponent } from './components/advert-prospects/prospect-rejection/prospect-rejection.component';
import { ProspectStatusAfterEmailComponent } from './components/advert-prospects/prospect-status-after-email/prospect-status-after-email.component';
import { ProspectStatusAfterSmsComponent } from './components/advert-prospects/prospect-status-after-sms/prospect-status-after-sms.component';
import { ProspectStatusComponent } from './components/advert-prospects/prospect-status/prospect-status.component';
import { ContactDetailsSectionComponent } from './components/advert-sections/contact-details-section/contact-details-section.component';
import { ImagesSectionComponent } from './components/advert-sections/images-section/images-section.component';
import { FormatTimeDirective } from './components/advert-sections/key-fact-section/format-time.directive';
import { KeyFactSectionComponent } from './components/advert-sections/key-fact-section/key-fact-section.component';
import { VirtualViewingSectionComponent } from './components/advert-sections/virtual-viewing-section/virtual-viewing-section.component';
import { AdvertTeaserComponent } from './components/advert-teaser/advert-teaser.component';
import { AdvertViewingsComponent } from './components/advert-viewings/advert-viewings.component';
import { AdvertComponent } from './components/advert/advert.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { HomeComponent } from './components/home/home.component';
import { IntranetGalleryComponent } from './components/intranet-gallery/intranet-gallery.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { RotationConfirmationDialogComponent } from './components/rotation/rotation-confirmation-dialog/rotation-confirmation-dialog.component';
import { RotationFilterComponent } from './components/rotation/rotation-filter/rotation-filter.component';
import { RotationListItemComponent } from './components/rotation/rotation-list-item/rotation-list-item.component';
import { RotationTeaserComponent } from './components/rotation/rotation-teaser/rotation-teaser.component';
import {
    AdvertRoomsLabelComponent,
    ApartmentHeaderStatsComponent,
    ApartmentSaleHeaderStatsComponent,
    ApartmentSaleStatsComponent,
    ApartmentStatsComponent,
    DownloadExposeComponent,
    LoadingIndicatorComponent,
    MessageBoxComponent,
    ParkingSpaceHeaderStatsComponent,
    ParkingSpaceStatsComponent,
    PhoneNumberComponent,
    PublishBarComponent,
    PublishComponent,
    ReservationComponent,
    TimePickerComponent,
    TimePickerDialogComponent,
} from './components/shared';
import { AdvertStateComponent } from './components/shared/advert-state/advert-state.component';
import { AutocompleteComponent } from './components/shared/autocomplete/autocomplete.component';
import { DownloadIcsComponent } from './components/shared/download-ics/download-ics.component';
import { FreeTextSearchComponent } from './components/shared/free-text-search/free-text-search.component';
import { InviteeStatsComponent } from './components/shared/invitee-stats/invitee-stats.component';
import { MultipleMessagesBoxComponent } from './components/shared/multiple-messages-box/multiple-messages-box.component';
import { NotificationComponent } from './components/shared/notification/notification.component';
import { StreetSearchComponent } from './components/shared/street-search/street-search.component';
import { ToggleButtonComponent } from './components/shared/toggle-button/toggle-button.component';
import { UnitSearchComponent } from './components/shared/unit-search/unit-search.component';
import { ViewUnitAcmComponent } from './components/shared/view-unit-acm/view-unit-acm.component';
import { ViewUnitIntranetComponent } from './components/shared/view-unit-intranet/view-unit-intranet.component';
import { SplashScreenComponent } from './components/splash-screen/splash-screen.component';
import { SystemErrorComponent } from './components/system-error/system-error.component';
import { TeaserFilterComponent } from './components/teaser-filter/teaser-filter.component';
import { TeaserListComponent } from './components/teaser-list/teaser-list.component';
import { ICONS } from './constants';
import { ClickAndKeydownDirective } from './directives/click-and-keydown.directive';
import { FeatureToggleDirective } from './directives/feature-toggle.directive';
import { HighlightActiveFormControlDirective } from './directives/highlight-active-form-control.directive';
import { LoadImageDirective } from './directives/load-image.directive';
import { ApiUrlInterceptor } from './interceptors/api-url.interceptor';
import { ServerErrorInterceptor } from './interceptors/server-error.interceptor';
import { AdditionalInfoByCountryPipe } from './pipes/additional-info-by-country.pipe';
import { NumeralPipe } from './pipes/numeral.pipe';
import { TotalRentPipe } from './pipes/total-rent.pipe';
import { routeConfig } from './route.config';

export function httpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        DashboardComponent,
        AdvertsComponent,
        CategoryComponent,
        AdvertStatusComponent,
        NavigationComponent,
        TeaserListComponent,
        TeaserFilterComponent,
        AdvertTeaserComponent,
        AdvertComponent,
        AdvertDetailsComponent,
        AdvertDetailsHeaderComponent,
        AdvertEventHistoryComponent,
        AdvertEventItemComponent,
        ViewingEventItemComponent,
        NotFoundComponent,
        SystemErrorComponent,
        AdvertProspectsComponent,
        GeneralInquiriesComponent,
        GeneralInquiryItemComponent,
        GeneralInquiryEditorComponent,
        GeneralInquiryCopyComponent,
        GeneralInquiryDeletionComponent,
        GeneralInquiryStatusComponent,
        GeneralInquiryStatusAfterEmailComponent,
        ProspectItemComponent,
        ProspectDeletionReasonComponent,
        ProspectStatusComponent,
        ProspectStatusAfterEmailComponent,
        ProspectEditorComponent,
        ProspectRejectionComponent,
        ProspectViewingActionsComponent,
        ProspectFormComponent,
        AdvertPreviewComponent,
        ContactDetailsSectionComponent,
        ImagesSectionComponent,
        KeyFactSectionComponent,
        VirtualViewingSectionComponent,
        ReservationComponent,
        IntranetGalleryComponent,
        PhoneNumberComponent,
        PublishComponent,
        PublishedPortalsComponent,
        PublishBarComponent,
        DownloadExposeComponent,
        TimePickerDialogComponent,
        AdvertRoomsLabelComponent,
        ApartmentStatsComponent,
        ParkingSpaceStatsComponent,
        ApartmentSaleStatsComponent,
        ApartmentHeaderStatsComponent,
        ApartmentSaleHeaderStatsComponent,
        ParkingSpaceHeaderStatsComponent,
        HuntingAreaStatsComponent,
        NumeralPipe,
        AdvertViewingsComponent,
        ViewingItemComponent,
        ViewingEditorComponent,
        TimePickerComponent,
        MessageBoxComponent,
        ViewingInviteesComponent,
        ViewingDialogComponent,
        DownloadIcsComponent,
        ToggleButtonComponent,
        InviteeStatsComponent,
        AdditionalInfoByCountryPipe,
        TotalRentPipe,
        ViewUnitIntranetComponent,
        AutocompleteComponent,
        PreviewInviteeStateComponent,
        FormatTimeDirective,
        AdvertImageUploadComponent,
        BytesPipe,
        ImageUploadComponent,
        PreviewUploadedImageComponent,
        LeavePageDialogComponent,
        ConvertDatePipe,
        ConvertTimePipe,
        SanitazedPhoneNumberPipe,
        ProspectStatusAfterSmsComponent,
        NotificationComponent,
        SplashScreenComponent,
        FeatureToggleDirective,
        MultipleMessagesBoxComponent,
        RotationTeaserComponent,
        RotationFilterComponent,
        RotationListItemComponent,
        RotationConfirmationDialogComponent,
        FreeTextSearchComponent,
        ViewUnitAcmComponent,
        HighlightActiveFormControlDirective,
        AdvertStateComponent,
        LoadImageDirective,
        PhoneNumberMenuComponent,
        UnitSearchComponent,
        StreetSearchComponent,
        CopyAdvertListComponent,
        CopyFilterComponent,
        ProspectCopyComponent,
        InfinityScrollingDirective,
        LoadingIndicatorComponent,
    ],
    bootstrap: [AppComponent],
    imports: [
        routeConfig,
        ClickAndKeydownDirective,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        CommonModule,
        ReactiveFormsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: httpLoaderFactory,
                deps: [HttpClient],
            },
            missingTranslationHandler: {
                provide: MissingTranslationHandler,
                useClass: AppMissingTranslationHandler,
            },
        }),
        AuthModule.forRoot(environment.auth),
        SentryModule.forRoot(environment.sentryConfiguration),
        MatButtonModule,
        MatIconModule,
        MatSelectModule,
        MatFormFieldModule,
        MatInputModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatMomentDateModule,
        MatDialogModule,
        MatSnackBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatChipsModule,
        MatTabsModule,
        MatTooltipModule,
        MatMenuModule,
        MatButtonToggleModule,
        MatAutocompleteModule,
        MatSlideToggleModule,
        ClipboardModule,
        DragDropModule,
        FileUploadModule,
        OverlayModule,
        CdkConnectedOverlay,
        MatTableModule,
        MatStepperModule,
        NgxGoogleAnalyticsModule.forRoot(environment.googleAnaliticsKey),
        ServiceWorkerModule.register('ngsw-worker.js'),
        MatProgressBarModule,
        MessageBoxModule,
    ],
    providers: [
        ConvertDatePipe,
        { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
        { provide: HTTP_INTERCEPTORS, useClass: ApiUrlInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ServerErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
        { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
        { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: { position: 'above' } },
        { provide: DateAdapter, useClass: MomentUtcDateAdapter },
        {
            provide: SwRegistrationOptions,
            useFactory: (environmentService: EnvironmentService) => ({
                enabled: environmentService.getEnvinronment().isServiceWorkerEnabled,
            }),
            deps: [EnvironmentService],
        },
        { provide: Window, useValue: window },
        {
            provide: MAT_BUTTON_TOGGLE_DEFAULT_OPTIONS,
            useValue: {
                hideSingleSelectionIndicator: true,
                hideMultipleSelectionIndicator: true,
            },
        },
        { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } },
        provideHttpClient(withInterceptorsFromDi()),
    ],
})
export class AppModule {
    private appUpdateService = inject(AppUpdateService);
    matIconRegistry = inject(MatIconRegistry);
    sanitizer = inject(DomSanitizer);

    constructor() {
        for (const icon of ICONS) {
            this.matIconRegistry.addSvgIcon(
                icon,
                this.sanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/${icon}.svg`), // NOSONAR
            );
        }
        this.appUpdateService.handleUpdates();
    }
}
