<div class="advert-event-history">
    <table *ngIf="!isEmpty">
        <tbody>
            <ng-container *ngFor="let event of events; trackBy: byEventId; let last = last">
                <tr
                    advert-event-item
                    *ngIf="isAdvertEvent(event)"
                    [event]="event"
                    infinityScrolling
                    (elementVisible)="onScroll(last)"
                    [isTargetElement]="last"
                ></tr>
                <tr
                    viewing-event-item
                    *ngIf="isViewingEvent(event)"
                    [event]="event"
                    infinityScrolling
                    (elementVisible)="onScroll(last)"
                    [isTargetElement]="last"
                ></tr>
            </ng-container>
        </tbody>
    </table>

    <section class="empty" *ngIf="events && isEmpty">
        <ak-message-box [type]="'info'">
            {{ errorMessage }}
        </ak-message-box>
    </section>
</div>

<loading-indicator></loading-indicator>
