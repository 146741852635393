<div id="prospects-view" class="prospects-view" *ngIf="prospects">
    <section class="filter" [formGroup]="searchAndFilter">
        <mat-form-field>
            <mat-icon matSuffix>search</mat-icon>
            <input type="text" formControlName="searchForm" matInput autocomplete="off" />
            <mat-label>{{ 'SHARED_COMPONENT.SEARCH_FOR_A_NAME' | translate }}</mat-label>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'PROSPECTS.STATUS' | translate }}</mat-label>
            <mat-select formControlName="prospectStatus">
                <mat-option>{{ 'SHARED_COMPONENT.NOT_SELECTED' | translate }}</mat-option>
                <mat-option *ngFor="let status of statusOptions; trackBy: byIndex" [value]="status">{{
                    'KEY_FACT_ENUMS.prospectStatus.' + status | translate
                }}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-checkbox formControlName="hasOnlyBookmarkedProspects">{{
            'SHARED_COMPONENT.ONLY_BOOKMARKED_PROSPECTS' | translate
        }}</mat-checkbox>
    </section>

    <article class="subtitle">
        <span>{{ 'PROSPECTS.PROSPECTS' | translate }}</span>
        @if (totalNumberOfElements > 0) {
            <span> {{ totalNumberOfElements }} </span>
        }
    </article>

    <div class="advert-prospects-actions">
        <div class="actions">
            <button mat-flat-button clickAndKeydown (actionTriggered)="createProspect()">
                <mat-icon>add</mat-icon>
                {{ 'PROSPECTS.PROSPECT' | translate }}
            </button>
        </div>
    </div>

    <div class="advert-prospects" *ngIf="!isEmpty">
        <aside class="selection">
            <mat-checkbox [ngModel]="hasAllItemsSelected" (ngModelChange)="selectAllChanged($event)">{{
                'SHARED_COMPONENT.SELECT_ALL' | translate
            }}</mat-checkbox>

            <section class="actions" [class.disabled]="!hasProspectsSelected">
                <button
                    mat-flat-button
                    clickAndKeydown
                    (actionTriggered)="bulkCopyProspect()"
                    matTooltip="{{ 'SHARED_COMPONENT.COPY' | translate }}"
                >
                    <mat-icon fontSet="material-icons-outlined">copy</mat-icon>
                </button>

                <button
                    mat-flat-button
                    clickAndKeydown
                    (actionTriggered)="setBulkStatus()"
                    matTooltip="{{ 'SHARED_COMPONENT.SET_STATUS' | translate }}"
                >
                    <mat-icon fontSet="material-icons-outlined">autorenew</mat-icon>
                </button>
                <button
                    mat-flat-button
                    clickAndKeydown
                    (actionTriggered)="setBulkDelete()"
                    matTooltip=" {{ 'SHARED_COMPONENT.DELETE' | translate }}"
                >
                    <mat-icon fontSet="material-icons-outlined">delete_outline</mat-icon>
                </button>
                <button
                    mat-flat-button
                    clickAndKeydown
                    (actionTriggered)="bulkRejectProspects()"
                    matTooltip="{{ 'SHARED_COMPONENT.REJECT' | translate }}"
                >
                    <mat-icon fontSet="material-icons-outlined">not_interested</mat-icon>
                </button>
                <button mat-flat-button [matMenuTriggerFor]="menu">
                    <mat-icon class="no-margin-left-and-right" iconPositionEnd>expand_more</mat-icon>
                    {{ 'SHARED_COMPONENT.SEND' | translate }}
                </button>

                <mat-menu #menu="matMenu">
                    <button mat-menu-item clickAndKeydown (actionTriggered)="writeBulkEmail()">
                        <mat-icon class="material-icons-outlined"> mail_outline </mat-icon>
                        {{ 'SHARED_COMPONENT.NEW_EMAIL' | translate }}
                    </button>
                    <prospect-viewing-actions
                        (setViewingInvitation)="setViewingInvitationForProspects()"
                        (setConfirmation)="setConfirmationForProspects()"
                    ></prospect-viewing-actions>
                </mat-menu>
            </section>
        </aside>

        <section class="prospect-message" *ngFor="let prospect of prospects; trackBy: byProspectId">
            <prospect-item
                [prospect]="prospect"
                (emailSelected)="writeEmail([$event])"
                (smsSelected)="writeSms([$event])"
                (editClicked)="editProspect($event)"
                (selectionChanged)="prospectSelectionChanged()"
                (setViewingInvitation)="setViewingInvitationForProspect($event)"
                (setConfirmation)="setConfirmationForProspect($event)"
                (prospectDeleted)="deleteProspect($event)"
                (prospectRejected)="rejectProspect($event)"
                (bookmarkChanged)="prospectBookmarkChanged()"
                (statusChanged)="setSingleStatus($event)"
                [canActivateActions]="hasProspectsSelected"
                [prospectIsEditable]="isEditable(prospect)"
                [countryCode]="countryCode"
                [region]="region"
            >
            </prospect-item>
        </section>
    </div>

    <div class="empty" *ngIf="isEmpty">
        <ak-message-box [type]="'info'">
            {{ 'PROSPECTS.NO_PROSPECTS_FOUND' | translate }}
        </ak-message-box>
    </div>
</div>

@if (isForbidden) {
    <div class="empty forbidden">
        <ak-message-box [type]="'warning'">
            {{ 'PROSPECTS.NOT_ALLOWED' | translate }}
        </ak-message-box>
    </div>
}

<loading-indicator></loading-indicator>
