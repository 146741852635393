<div class="teaser-list">
    <div class="title">
        {{ 'TEASER_LIST.TITLE' | translate }}
        <div
            class="filter-button"
            [class.active]="showUnitListFilter"
            clickAndKeydown
            (actionTriggered)="toggleUnitListFilter()"
        >
            <mat-icon>filter_list</mat-icon>
        </div>
    </div>

    <teaser-filter
        [showUnitListFilter]="showUnitListFilter"
        [filterData]="teaserFilterData"
        (filterChanged)="onFilterChanged($event)"
    >
    </teaser-filter>

    <article class="subtitle" *ngIf="adverts?.length > 0">
        {{ 'FILTER_HINTS.totalElements' | translate }} {{ totalNumberOfElements }}
    </article>

    <section class="teaser-data" *ngIf="adverts?.length > 0">
        <div class="teaser" *ngFor="let advert of adverts; trackBy: byAdvertId">
            <advert-teaser
                [advert]="advert"
                (advertDataChanged)="onFilterChanged()"
                (unpublishAdvertEvent)="unpublishAdvertEvent()"
            ></advert-teaser>
        </div>
    </section>

    <div class="empty">
        @if (adverts?.length === 0) {
            <ak-message-box [type]="'info'">{{ 'SHARED_COMPONENT.NO_TEASER_WARNING' | translate }}</ak-message-box>
        }
    </div>
</div>

<loading-indicator></loading-indicator>
