@if (topPublishedCategory && topPublishedCategory.itemsCount) {
    <aside data-test="published-units" class="category" aria-label="published units category">
        <category
            [category]="topPublishedCategory"
            (bookmarkedListChanged)="updateBookmarkedList($event)"
            (publishedListChanged)="updatePublishedList($event)"
            (checkHasPublishedAdverts)="updateHasPublishedAdverts()"
        >
        </category>
    </aside>
}
@if (bookmarkedCategory && bookmarkedCategory.itemsCount) {
    <aside data-test="bookmarked-units" class="category" aria-label="bookmarked units category">
        <category
            [category]="bookmarkedCategory"
            (bookmarkedListChanged)="updateBookmarkedList($event)"
            (publishedListChanged)="updatePublishedList($event)"
        >
        </category>
    </aside>
}

@if (hasNoPublishedAdverts) {
    <aside data-test="no-published-units" class="empty" aria-label="pops up when no units are published">
        <ak-message-box [type]="'info'">
            {{ 'SHARED_COMPONENT.NO_PUBLISHED_ADVERTS' | translate }}
        </ak-message-box>
    </aside>
}

<loading-indicator></loading-indicator>
