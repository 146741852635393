<div class="option-modal" [ngSwitch]="viewType">
    <section class="head">
        <article class="subtitle">{{ 'SHARED_COMPONENT.SET_STATUS' | translate }}</article>
        <button mat-icon-button mat-dialog-close [disabled]="isLoading">
            <mat-icon>close</mat-icon>
        </button>
    </section>

    <section class="options" *ngSwitchCase="'status'">
        <mat-form-field>
            <mat-label>{{ 'SHARED_COMPONENT.STATUS' | translate }}</mat-label>
            <mat-select [(ngModel)]="selectedStatus">
                <mat-option *ngFor="let item of options; trackBy: byIndex" [value]="item.context">{{
                    item.name | translate
                }}</mat-option>
            </mat-select>
        </mat-form-field>
    </section>

    <section class="loading" *ngSwitchCase="'loading'">
        <loading-indicator [isPermanent]="true"></loading-indicator>
    </section>

    <section class="actions" *ngIf="viewType !== 'loading'">
        <button mat-flat-button mat-dialog-close>
            {{ 'SHARED_COMPONENT.CANCEL' | translate }}
        </button>
        <button
            mat-flat-button
            color="primary"
            clickAndKeydown
            (actionTriggered)="save()"
            *ngIf="viewType === 'status'"
        >
            {{ 'SHARED_COMPONENT.SUBMIT' | translate }}
        </button>
    </section>
</div>
