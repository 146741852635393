<div class="app-update let-mat-dialog">
    <section class="head">
        <article class="subtitle">{{ 'PWA.TITLE' | translate }}</article>
        <button mat-icon-button clickAndKeydown (actionTriggered)="close()">
            <mat-icon>close</mat-icon>
        </button>
    </section>

    <section class="info">
        <ak-message-box [type]="'info'">
            {{ 'PWA.UPDATE_INFO' | translate }}
        </ak-message-box>
    </section>

    <section class="actions">
        <button mat-flat-button clickAndKeydown (actionTriggered)="close()">
            {{ 'SHARED_COMPONENT.CANCEL' | translate }}
        </button>
        <button mat-flat-button color="primary" clickAndKeydown (actionTriggered)="save()">
            {{ 'PWA.UPDATE' | translate }}
        </button>
    </section>
</div>
