import { Component, OnInit, inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ProspectHelper } from '@helpers/prospect.helper';
import { ProspectStatus } from '@models/backend/prospects';
import { IDropdownItem } from '@models/common/dropdown-item';
import { CountryService } from '@services/country.service';
import { takeUntil } from 'rxjs';
import { UnSubscriptionDirective } from 'src/app/directives/unsubscribe.directive';

@Component({
    selector: 'prospect-status',
    templateUrl: 'prospect-status.component.html',
    styleUrls: ['prospect-status.component.less'],
})
export class ProspectStatusComponent extends UnSubscriptionDirective implements OnInit {
    private countryService = inject(CountryService);

    dialogRef = inject<MatDialogRef<ProspectStatusComponent>>(MatDialogRef);

    options: IDropdownItem<ProspectStatus>[];
    selectedStatus: ProspectStatus;
    viewType: 'status' | 'loading' = 'status';

    get isLoading(): boolean {
        return this.viewType === 'loading';
    }

    ngOnInit(): void {
        this.options = this.getProspectStatusItems();
        this.selectedStatus = this.options[0].context;
        this.dialogRef
            .backdropClick()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => this.dialogRef.close());
    }

    save(): void {
        this.dialogRef.close(this.selectedStatus);
    }

    private getProspectStatusItems(): IDropdownItem<ProspectStatus>[] {
        const country = this.countryService.getCurrentCountry();
        const allOptions = ProspectHelper.getStatusOptions(country);
        return allOptions.map((s) => {
            const item: IDropdownItem<ProspectStatus> = {
                context: s,
                name: `KEY_FACT_ENUMS.prospectStatus.${s}`,
            };
            return item;
        });
    }

    byIndex(index: number): number {
        return index;
    }
}
