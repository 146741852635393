<div class="message-box-container">
    <div class="message-box-side-pannel">
        <i class="material-icons info">info</i>
    </div>
    <div class="message-box-content">
        <section
            class="message-box-content-section"
            *ngFor="let validationMessage of validationMessages; trackBy: byIndex"
        >
            <i class="material-icons {{ validationMessage.iconType }}">{{ validationMessage.iconType }} </i>

            <ng-container *ngIf="validationMessage.suffix as maxNumberOfImages; else elseBlock">
                {{ validationMessage.message | translate: { maxNumberOfImages } }}
            </ng-container>
            <ng-template #elseBlock>
                {{ validationMessage.message | translate }}
            </ng-template>
        </section>
    </div>
</div>
