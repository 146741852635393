<aside class="list" *ngIf="adverts.length > 0" [ngClass]="{ bookmark: isBookmarksMode, visible: display }">
    <div class="list-item" *ngFor="let advert of adverts; trackBy: byAdvertId">
        <article class="id">
            <button
                class="bookmark"
                *ngIf="isBookmarksMode"
                mat-icon-button
                clickAndKeydown
                (actionTriggered)="removeBookmark(advert)"
                matTooltip="{{ 'SHARED_COMPONENT.REMOVE_BOOKMARK' | translate }}"
            >
                <i class="material-icons">star</i>
            </button>

            <a class="link" routerLink="/advert/{{ advert.id }}">{{ advert.id }}</a>

            <div class="open-new-tab" *ngIf="!isStandaloneDisplayMode">
                <button
                    mat-icon-button
                    clickAndKeydown
                    (actionTriggered)="openInNewTab(advert)"
                    matTooltip="{{ 'SHARED_COMPONENT.OPEN_IN_NEW_TAB' | translate }}"
                >
                    <mat-icon>open_in_new</mat-icon>
                </button>
            </div>
        </article>

        <article class="address">{{ advert.streetNameAndHouseNumber }} {{ advert.direction }}</article>
        <mat-chip-set>
            <mat-chip *ngIf="advert.isPublished">{{ getTranslatedDaysSincePublished(advert)() | async }}</mat-chip>
            <mat-chip *ngIf="advert.isReserved">{{ getTranslatedDaysSinceReserved(advert)() | async }}</mat-chip>
        </mat-chip-set>
        <div class="actions">
            <ng-container
                *ngTemplateOutlet="
                    ConfirmedAttendeesTemplate;
                    context: {
                        advert: advert,
                    }
                "
            >
            </ng-container>

            <button
                class="messages yellow"
                mat-flat-button
                *ngIf="advert.hasNewProspectMessages && !isUsAdvertOnProdEnvironment(advert)"
                matTooltip="{{ 'SHARED_COMPONENT.NEW_PROSPECTS' | translate }}"
                routerLink="/advert/{{ advert.id }}/prospects"
                [queryParams]="{ view: 'prospects-view' }"
            >
                <mat-icon fontSet="material-icons-outlined">person</mat-icon>

                {{ advert.numberOfNewProspects }}
            </button>

            <button
                class="upcoming-viewings"
                mat-flat-button
                *ngIf="advert.hasUpcomingViewings"
                matTooltip="{{ 'SHARED_COMPONENT.UPCOMING_VIEWINGS_PLURAL' | translate }}"
                routerLink="/advert/{{ advert.id }}/viewings"
                [queryParams]="{ view: 'viewings-view' }"
            >
                <mat-icon fontSet="material-icons-outlined">event</mat-icon>
                {{ advert.numberOfUpcomingViewings }}
            </button>

            <button mat-flat-button color="primary" [matMenuTriggerFor]="menu" *ngIf="advert.isPublished">
                {{ 'SHARED_COMPONENT.ACTIONS' | translate }}
                <mat-icon class="no-margin-left-and-right" iconPositionEnd>expand_more</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item routerLink="/advert/{{ advert.id }}">
                    <mat-icon class="material-icons-outlined">create</mat-icon>
                    {{ 'SHARED_COMPONENT.EDIT' | translate }}
                </button>
                <button class="action-item" mat-menu-item clickAndKeydown (actionTriggered)="toggleReservation(advert)">
                    <mat-icon>{{ advert.isReserved ? 'close' : 'schedule' }}</mat-icon>
                    <span>{{
                        advert.isReserved
                            ? ('SHARED_COMPONENT.CANCEL_RESERVATION' | translate)
                            : ('SHARED_COMPONENT.RESERVE' | translate)
                    }}</span>
                </button>
                <button mat-menu-item clickAndKeydown (actionTriggered)="unpublishAdvert(advert)">
                    <mat-icon class="material-icons-outlined">cloud_off</mat-icon>
                    {{ 'SHARED_COMPONENT.UNPUBLISH' | translate }}
                </button>
                <button mat-menu-item clickAndKeydown (actionTriggered)="createProspect(advert.id)">
                    <mat-icon class="material-icons-outlined">add</mat-icon>
                    {{ 'SHARED_COMPONENT.ADD_PROSPECT' | translate }}
                </button>
            </mat-menu>
        </div>
    </div>
</aside>

<ng-template #ConfirmedAttendeesTemplate let-advert="advert">
    <button
        *ngIf="advert.hasViewingsWithConfirmedAttendees"
        routerLink="/advert/{{ advert.id }}/viewings"
        [queryParams]="{ filterBy: 'attendee-confirmation' }"
        routerLinkActive="router-link-active"
        class="yellow confirmed"
        matTooltip="{{ 'SHARED_COMPONENT.CONFIRMED_ATTENDED' | translate }}"
        mat-flat-button
    >
        <mat-icon fontSet="material-icons-outlined">event_available</mat-icon>

        {{ advert.numberOfViewingsWithConfirmedAttendees }}
    </button>
</ng-template>
