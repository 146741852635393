import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { SupportedCountryCode } from '@models/backend/common';
import { CountryService } from '@services/country.service';
import { EnvironmentService } from '@services/environment.service';
import { Observable } from 'rxjs';
import { v5 } from 'uuid';

@Injectable()
export class ApiUrlInterceptor implements HttpInterceptor {
    private countryService = inject(CountryService);
    private environmentService = inject(EnvironmentService);

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (request.url.includes('assets')) {
            return next.handle(request);
        }

        const { baseUrl, version } = this.environmentService.getEnvinronment().apiConfig;
        const currentCountry = this.countryService.getCurrentCountry();

        const modified = request.clone({
            url: `${baseUrl}/api/v${version}/${request.url}`,
            params: request.params.append('transactionId', v5.URL).set('countryCode', currentCountry),
            headers: request.headers.append('x-akelius-tenant', this.#getTenantName(currentCountry)),
        });

        return next.handle(modified);
    }

    // TODO (LA-6074): discuss with the team how to set the tenant header in future
    #getTenantName(countryCode: SupportedCountryCode): string {
        switch (countryCode) {
            case 'SE':
                return 'akelius-forest';
            default:
                return 'akelius-residential';
        }
    }
}
