<section class="head">
    <article class="subtitle ellipsis-title">{{ 'SHARED_COMPONENT.PROSPECT_COPY.DEFAULT_TITLE' | translate }}</article>
    <button mat-icon-button clickAndKeydown (actionTriggered)="cancel()">
        <mat-icon>close</mat-icon>
    </button>
</section>

<ng-template [ngIf]="showAdvertList">
    <copy-filter (advertsFilterEvent)="filterChanged($event)" />

    <copy-advert-list (scrollEventTriggered)="onScroll()" [partialAdvertList]="partialAdverts" />

    <section class="actions">
        <button mat-flat-button matDialogClose>{{ 'SHARED_COMPONENT.CANCEL' | translate }}</button>
        <button
            mat-flat-button
            color="primary"
            [disabled]="isConfirmButtonEnabled()"
            clickAndKeydown
            (actionTriggered)="confirm()"
        >
            {{ 'SHARED_COMPONENT.COPY' | translate }}
        </button>
    </section>
</ng-template>

<ng-container [ngTemplateOutlet]="selectedTemplate"></ng-container>

<ng-template #prospectCreationConfirmationTemplate>
    <div>
        @if (prospects.length > 1) {
            <p>{{ prospects.length }} {{ 'PROSPECTS.PROSPECTS' | translate }}</p>
        } @else {
            <p>{{ prospects.length }} {{ 'PROSPECTS.PROSPECT' | translate }}</p>
        }
    </div>

    <section>
        {{ 'SHARED_COMPONENT.PROSPECT_COPY.BODY' | translate }}
        <br />
        <ng-container *ngFor="let item of partialAdverts; trackBy: byAdvertId">
            <ng-container *ngIf="item.isSelected">
                - {{ item.context.advertId }}, {{ item.context.streetName }}
                <ng-container *ngIf="item.context.numberOfBedrooms"
                    >, {{ item.context.numberOfBedrooms }}
                    {{ 'SHARED_COMPONENT.COPY_TO_ADVERT.BED' | translate }}</ng-container
                >
                <ng-container *ngIf="item.context.numberOfBathrooms"
                    >, {{ item.context.numberOfBathrooms }}
                    {{ 'SHARED_COMPONENT.COPY_TO_ADVERT.BATH' | translate }}</ng-container
                >
                <ng-container *ngIf="item.context.totalRent">, {{ item.context.totalRent }}</ng-container>
            </ng-container>
        </ng-container>
    </section>

    <section class="actions">
        <button mat-flat-button matDialogClose>{{ 'SHARED_COMPONENT.CANCEL' | translate }}</button>
        <button mat-flat-button color="primary" clickAndKeydown (actionTriggered)="copy()">
            {{ 'SHARED_COMPONENT.PROSPECT_COPY.CONFIRM' | translate }}
        </button>
    </section>
</ng-template>

<ng-template #prospectCreatedTemplate>
    <ak-message-box [type]="'success'" clickAndKeydown (actionTriggered)="cancel()" (onkeydown)="cancel()">
        {{ getMessage('SHARED_COMPONENT.PROSPECT_COPY.PROSPECTS_SUCCESS', 'SHARED_COMPONENT.PROSPECT_COPY.SUCCESS') }}
        <a routerLink="/advert/{{ targetAdvertId }}/prospects">{{ targetAdvertId }}.</a>
    </ak-message-box>
</ng-template>

<ng-template #prospectPartialyCreatedTemplate>
    <ak-message-box [type]="'warning'" clickAndKeydown (actionTriggered)="cancel()" (onkeydown)="cancel()">
        {{ 'SHARED_COMPONENT.PROSPECT_COPY.PROSPECTS_PARTIALY_SUCCESS' | translate }}
        <a routerLink="/advert/{{ targetAdvertId }}/prospects">{{ targetAdvertId }}.</a>
    </ak-message-box>

    <h4 class="partialy-subtitle">
        {{ 'SHARED_COMPONENT.PROSPECT_COPY.PROSPECTS_PARTIALY_SUCCESS_TITLE' | translate }}
    </h4>
    <div class="scrollable-list">
        <ng-container>
            @for (prospect of failedProspects; track $index) {
                <span class="failed-prospect"> {{ prospect.name }}, {{ prospect.email }} </span>
            }
        </ng-container>
    </div>
    <section class="actions">
        <button mat-flat-button color="primary" clickAndKeydown (actionTriggered)="backToAdvertList()">
            {{ 'SHARED_COMPONENT.BACK' | translate }}
        </button>
    </section>
</ng-template>

<ng-template #prospectCreationWarningTemplate>
    <ak-message-box [type]="'warning'" clickAndKeydown (actionTriggered)="cancel()" (onkeydown)="cancel()">
        {{ warningMessage() }}
        <a routerLink="/advert/{{ targetAdvertId }}/prospects">{{ targetAdvertId }}.</a>
    </ak-message-box>

    <section class="actions">
        <button mat-flat-button color="primary" clickAndKeydown (actionTriggered)="backToAdvertList()">
            {{ 'SHARED_COMPONENT.BACK' | translate }}
        </button>
    </section>
</ng-template>

<ng-template #prospectCreationErrorTemplate>
    <ak-message-box [type]="'error'">
        {{ getMessage('SHARED_COMPONENT.PROSPECT_COPY.PROSPECTS_ERROR', 'SHARED_COMPONENT.PROSPECT_COPY.ERROR') }}
    </ak-message-box>
</ng-template>

<loading-indicator></loading-indicator>
