@if (generalInquiry) {
    <div class="general-inquiry-item">
        <section class="header">
            <article class="heading">
                {{ generalInquiry.name }}
            </article>
            <button
                mat-icon-button
                clickAndKeydown
                (actionTriggered)="edit()"
                matTooltip="{{ 'SHARED_COMPONENT.EDIT' | translate }}"
            >
                <mat-icon fontSet="material-icons-outlined">edit</mat-icon>
            </button>
        </section>

        <section class="stats">
            <div class="stat">
                <article>
                    {{ generalInquiry.dateOfReceipt | convertDate }},
                    {{ generalInquiry.dateOfReceipt | convertTime }}
                </article>
                <a href="mailto:{{ generalInquiry.email }}" class="link">{{ generalInquiry.email }}</a>
                <mat-error class="error-msg" *ngIf="!isValidEmail"
                    ><mat-icon>info</mat-icon>{{ 'VALIDATION_MESSAGES.EMAIL' | translate }}</mat-error
                >

                <phone-number-menu [phoneNumber]="generalInquiry.phone | sanitazedPhoneNumber"></phone-number-menu>
            </div>
        </section>

        <mat-chip-set class="pills">
            <mat-chip data-test="portalName">{{ 'PORTALS.' + generalInquiry.portalName | translate }}</mat-chip>
            <mat-chip color="primary">
                {{ 'GENERAL_INQUIRIES_VIEW.STATUS.' + generalInquiry.status | translate }}
            </mat-chip>
        </mat-chip-set>

        <section class="message">
            <article
                class="heading"
                *ngIf="generalInquiry.hasMessage"
                clickAndKeydown
                (actionTriggered)="toggleExpand()"
                (onkeydown)="toggleExpand()"
            >
                <mat-icon class="no-margin-right">{{ carretIcon }}</mat-icon>
                {{ 'SHARED_COMPONENT.MESSAGE' | translate }}
            </article>
            <article class="text" *ngIf="isExpanded" [style.white-space]="'pre-wrap'">{{ message() }}</article>
        </section>

        <section class="actions">
            <button
                mat-icon-button
                clickAndKeydown
                (actionTriggered)="copy()"
                matTooltip="{{ 'SHARED_COMPONENT.COPY' | translate }}"
            >
                <mat-icon class="material-icons-outlined">arrow_forward</mat-icon>
            </button>
            <button
                mat-icon-button
                clickAndKeydown
                (actionTriggered)="setStatus()"
                matTooltip="{{ 'SHARED_COMPONENT.SET_STATUS' | translate }}"
            >
                <mat-icon class="material-icons-outlined">autorenew</mat-icon>
            </button>
            <button
                mat-icon-button
                clickAndKeydown
                (actionTriggered)="delete()"
                matTooltip="{{ 'SHARED_COMPONENT.DELETE' | translate }}"
            >
                <mat-icon>delete_outline</mat-icon>
            </button>
            <button [disabled]="!isValidEmail" color="primary" mat-flat-button [matMenuTriggerFor]="menu">
                {{ 'SHARED_COMPONENT.SEND' | translate }}
                <mat-icon class="no-margin-left-and-right" iconPositionEnd>expand_more</mat-icon>
            </button>

            <mat-menu #menu="matMenu">
                <button mat-menu-item clickAndKeydown (actionTriggered)="writeEmail()">
                    <mat-icon class="material-icons">mail_outline</mat-icon>
                    {{ 'SHARED_COMPONENT.NEW_EMAIL' | translate }}
                </button>
            </mat-menu>
        </section>
    </div>
}
